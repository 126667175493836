import { FETCHING, FETCHED, ERROR } from "./authModule/types";

export function fetching(TYPE, aditional = null) {
  return {
    type: TYPE + FETCHING,
    aditional
  };
}

export function fetched(TYPE, response, aditional = null) {
  return {
    type: TYPE + FETCHED,
    payload: response,
    aditional
  };
}

export function error(TYPE, response, aditional = null) {
  return {
    type: TYPE + ERROR,
    payload: response,
    aditional
  };
}

export default (dispatch, TYPE, promise, aditional = null) => {
  dispatch(fetching(TYPE, aditional));
  promise
    .then(r => {
      dispatch(fetched(TYPE, r, aditional));
    })
    .catch(r => {
      dispatch(error(TYPE, r, aditional));
    });
};

export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'
export const RESET = '_RESET'


//COTIZADOR STEM
export const UPDATE_QUOTATION_STEM = "UPDATE_QUOTATION_STEM"
export const UPDATE_QUOTATION_STEM_CONTACT = "UPDATE_QUOTATION_STEM_CONTACT"
export const UPDATE_QUOTATION_STEM_BENEFICIARY = "UPDATE_QUOTATION_STEM_BENEFICIARY"
export const UPDATE_QUOTATION_STEM_TUTOR = "UPDATE_QUOTATION_STEM_TUTOR"
export const UPDATE_QUOTATION_STEM_ADD_DEPENT = "UPDATE_QUOTATION_STEM_ADD_DEPENT"
export const UPDATE_QUOTATION_STEM_SPLICE_DEPENT = "UPDATE_QUOTATION_STEM_SPLICE_DEPENT"
export const UPDATE_QUOTATION_STEM_DEPENT = "UPDATE_QUOTATION_STEM_DEPENT"
export const API_POST_COTIZACIONES_CELULAS_MADRE = "API_POST_COTIZACIONES_CELULAS_MADRE"
export const ADD_REMOVE_PROGRAM_CELULAS = "ADD_REMOVE_PROGRAM_CELULAS"
export const API_GET_FILTER_BOX = "API_GET_FILTER_BOX"
export const API_UPDATE_FILTER_BOX = "API_UPDATE_FILTER_BOX"
export const API_UPDATE_FILTER_BOX_UPDATE = "API_UPDATE_FILTER_BOX_UPDATE"
export const UPDATE_QUOTATION_STEM_CONTACT_API = "UPDATE_QUOTATION_STEM_CONTACT_API"
export const UPDATE_QUOTATION_STEM_CONTACT_ALL = "UPDATE_QUOTATION_STEM_CONTACT_ALL"
export const RESET_FETCH_CONTACT = "RESET_FETCH_CONTACT"
export const RESET_FETCH_BILLING = "UPDATE_QUOTATION_STEM_BILLING"
export const UPDATE_QUOTATION_STEM_BILLING_API = "UPDATE_QUOTATION_STEM_BILLING_API"
export const UPDATE_QUOTATION_STEM_BILLING_ALL = "UPDATE_QUOTATION_STEM_BILLING_ALL"
export const GET_QUOTATION_STEM_BILLING = "GET_QUOTATION_STEM_BILLING"
export const PUT_QUOTATION_STEM_BILLING_API = "PUT_QUOTATION_STEM_BILLING_API"
export const GET_QUOTATION_STEM_INSUREDS = "GET_QUOTATION_STEM_INSUREDS"
export const GET_QUOTATION_STEM_LEGAL_GUARDIAN = "GET_QUOTATION_STEM_LEGAL_GUARDIAN"
export const PUT_QUOTATION_STEM_INSURED_API = "PUT_QUOTATION_STEM_INSURED_API"
export const GET_QUOTATION_STEM_LABS = "GET_QUOTATION_STEM_LABS"
export const PUT_QUOTATION_STEM_LEGAL_GUARDIAN = "PUT_QUOTATION_STEM_LEGAL_GUARDIAN"
export const GET_QUOTATION_STEM_QUESTIONS = "GET_QUOTATION_STEM_QUESTIONS"
export const POST_QUOTATION_STEM_QUESTIONS_ANSWERS = "POST_QUOTATION_STEM_QUESTIONS_ANSWERS"
export const UPDATE_QUOTATION_STEM_PAY = "UPDATE_QUOTATION_STEM_PAY"
export const UPDATE_QUOTATION_STEM_PAY2 = "UPDATE_QUOTATION_STEM_PAY2"
export const POST_EMIT_STEM_QUOTATION = "POST_EMIT_STEM_QUOTATION"
export const PUT_QUOTATION_STEM_OPTIONS = "PUT_QUOTATION_STEM_OPTIONS"
export const POST_GET_STEM_QUOTATION_OPTIONS = "POST_GET_STEM_QUOTATION_OPTIONS"
export const SET_DATA_QUOTATION_STEM = "SET_DATA_QUOTATION_STEM"
export const CREATE_QUOTATION_STEM_PAY_API = "CREATE_QUOTATION_STEM_PAY_API"
export const UPDATE_QUOTATION_STEM_PAY_API = "UPDATE_QUOTATION_STEM_PAY_API"
export const SEND_EMAIL_QUOTATION_STEM_API = "SEND_EMAIL_QUOTATION_STEM_API"
export const SEND_EMAIL_QUOTATION_STEM_RESET = "SEND_EMAIL_QUOTATION_STEM_RESET"
export const CHANGE_SEARCH_QUOTES = "CHANGE_SEARCH_QUOTES"
export const FILTER_COTIZACION_STEM = "FILTER_COTIZACION_STEM"
export const API_GET_VENTAS_STEM = "API_GET_VENTAS_STEM"
export const API_GET_COTIZACIONES_STEM = "API_GET_COTIZACIONES_STEM"
export const API_GET_QUOTE_STATUSES_STEM = "API_GET_QUOTE_STATUSES_STEM"
export const API_GET_SPECIAL_COVERAGE_STEM = "API_GET_SPECIAL_COVERAGE_STEM"
export const API_GET_TRAVEL_TYPES_STEM = "API_GET_TRAVEL_TYPES_STEM"
export const RESET_COTIZACION_STEM = "RESET_COTIZACION_STEM"
export const SEARCH_COTIZACION_STEM = "SEARCH_COTIZACION_STEM"
export const SEARCH_SALE_STEM = "SEARCH_SALE_STEM"
export const VENTA_SELECT_STEM = "VENTA_SELECT_STEM"
export const VALIDATE_QUOTE_FORM_STEM = "VALIDATE_QUOTE_FORM_STEM"
export const OFF_VALIDATION_TYPE = "OFF_VALIDATION_TYPE"
export const RESET_PAGINATION = "RESET_PAGINATION"
export const FILTER_VENTA_STEM = "FILTER_VENTA_STEM"
export const UPDATE_PROGRAMS_CELULAS = "UPDATE_PROGRAMS_CELULAS"
export const EXTRA_PARAMS = "EXTRA_PARAMS"
export const RESET_EXTRA_PARAMS = "RESET_EXTRA_PARAMS"
export const PUT_STEM_ALL_INSUREDS = "PUT_STEM_ALL_INSUREDS"
export const UPDATE_QUOTE_ID = "UPDATE_QUOTE_ID"
export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID"
export const SET_ERROR_COTIZADOR_STEM = "SET_ERROR_COTIZADOR_STEM"
export const PUT_QUOTATION_PRINCIPAL_OPTION = "PUT_QUOTATION_PRINCIPAL_OPTION"
export const GET_COTIZACION = "GET_COTIZACION"
export const RESET_UPDATE_CONTACT_QUOTATION = "RESET_UPDATE_CONTACT_QUOTATION"
export const UPDATE_PROGRAMS_CELULAS_ALL = "UPDATE_PROGRAMS_CELULAS_ALL"
export const REMOVE_ALL_PROGRAM = "REMOVE_ALL_PROGRAM"
export const API_GET_PROGRAMS_STEM = "API_GET_PROGRAMS_STEM"
export const API_GET_PROGRAMS_STEM_ID = "API_GET_PROGRAMS_STEM_ID"
export const SET_PROGRAM_VALUE_STEM = "SET_PROGRAM_VALUE_STEM"
export const API_GET_EDIT_INSURERS_STEM = "API_GET_EDIT_INSURERS_STEM"
export const SET_PROGRAM_VALUE_STEM_USUARIO = "SET_PROGRAM_VALUE_STEM_USUARIO"
export const API_GET_EDIT_INSURERS_USUARIO_STEM = "API_GET_EDIT_INSURERS_USUARIO_STEM"
export const API_GET_COACH_USUARIO_STEM = "API_GET_COACH_USUARIO_STEM"
export const API_GET_INSURERS_SALES = "API_GET_INSURERS_SALES"
export const REMOVE_ALL_PROGRAMS_SELECTED = "REMOVE_ALL_PROGRAMS_SELECTED"
//COTIZACION GENERAL
export const API_GET_PROGRAM_TYPES = "API_GET_PROGRAM_TYPES"
export const API_GET_PAYMENT_TYPES = "API_GET_PAYMENT_TYPES"
export const API_GET_RELATIONSHIPS_TYPES = "API_GET_RELATIONSHIPS_TYPES"

//EMAIL
export const EMAIL_API_GET_COTIZACION = "EMAIL_API_GET_COTIZACION"

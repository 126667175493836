export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'

//GENERAL
export const CHANGE_TAB_MENU = "CHANGE_TAB_MENU"
export const API_GET_COUNTRIES = "API_GET_COUNTRIES"
export const API_GET_STATES = "API_GET_STATES"
export const API_GET_STATES_US = "API_GET_STATES_US"
export const API_GET_CONFIGS = "API_GET_CONFIGS"
export const API_GET_ROLES = "API_GET_ROLES"
export const API_GET_PROFILE = "API_GET_PROFILE"
export const API_POST_VALID_DOMINE = "API_POST_VALID_DOMINE"
export const API_GET_PUBLIC_INSURERS = "API_GET_PUBLIC_INSURERS"
export const RESET_SUCCESS_PROFILE = "RESET_SUCCESS_PROFILE"
export const API_GET_BENEFICIARIES_B2C = "API_GET_BENEFICIARIES_B2C"
export const API_GET_SECTORS = "API_GET_SECTORS"
export const RESET_STATES = "RESET_STATES"


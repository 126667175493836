import { createStore, applyMiddleware } from "redux";
import rootReducer from "./rootReducer";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web
import autoMergeLevel1 from 'redux-persist/lib/stateReconciler/autoMergeLevel1'

const initialState = {};

const persistConfig = {
  key: 'rootKerangoDashboardStore',
  storage,
  stateReconciler: autoMergeLevel1,
  keyPrefix:''
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const middlewares = applyMiddleware(thunk, logger);

export const store = createStore(persistedReducer, initialState, middlewares);

export const persistor = persistStore(store)










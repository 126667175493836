import * as ActionsTypes from "./types";

const ini = {
  quotation_stem: {
    type: "",
    paymentTypee: "",
    dependents: [],
    legalGuardian: {
      name: "",
      lastName: "",
      phone: "",
      email: ""
    },
    contact: {
      name: "",
      lastName: "",
      phone: "",
      email: ""
    },
    beneficiary: {
      type: "",
      name: "",
      lastName: "",
      gender: "",
      birthDate: "",
      lab: null,
      residency: null,
    },
    billing: {
      id: false,
      name: "",
      lastName: "",
      document: "",
      address: "",
      city: "",
      zip: "",
      state: "",
      country: ""
    },
    pay: {
      name: "",
      number: ["", "", "", ""],
      expiration: ["", ""],
      ccv: ""
    },
    insureds: [],
    answers: [],
    done_answers: false,
    done_pay: false,
    card: {
      feching: false,
      feched: false,
      result: null,
      card: null,
    },
  },
  pagination: {
    step: 10,
    page: 0,
    pages: 0,
    total: 0,
  },
  error:{
    cotizador:{
      type: false,
      paymentType: false,
      contact:{
        firstName: false,
        lastName: false,
        email:false,
        phone:false
      },
      beneficiary:{
        name: false,
        lastName: false,
        birthDate: false,
        residency: false,
        residency2: false,
        gender: false
      }
    }
  },
  questions: [],
  labs: [],
  cotizaciones: [],
  cotizaciones_filter: [],
  ventas: [],
  ventas_filter: [],
  cotizacion_selected: {},
  venta_selected: {},
  specialCoverages: [],
  document_types: [],
  payment_types: [],
  program_types: [],
  programs_celulas: [],
  contact_update_fetching: true,
  contact_update_fetched: null,
  contact_update_result: null,
  billing_update_fetched: false,
  billing_update_fetching: false,
  billing_update_result: null,
  post_answers_fetched: false,
  post_answers_fetching: false,
  post_answers_result: null,
  post_answers_error:false,
  insureds_put_fetched: null,
  insureds_put_fetching: null,
  insureds_put_result: null,
  legal_guardian_put_fetched: null,
  legal_guardian_put_fetching: null,
  legal_guardian_put_result: null,
  insureds_get_fetched: null,
  id_quotation_celulas: "",
  programs_celulas_fetching: null,
  programs_celulas_fetched: null,
  filter_celulas_fetching: null,
  filter_celulas_fetched: null,
  filters_celulas: [],
  polizas_celulas_selected: [],
  relationship_types: [],
  statuses: [],
  statuses_feching: false,
  statuses_feched: false,
  insurers: [],
  polizas_selected: [],
  id_quotation: null,
  programs: [],
  travel_types: [],
  success: null,
  messages: "",
  program_selected: {},
  fetching: null,
  fetched: null,
  send_fetching: null,
  send_fetched: null,
  send_data: null,
  programs_fetching: null,
  programs_fetched: null,
  emit_celulas_fetched: null,
  emit_celulas_fetching: null,
  emit_celulas_error:null,
  result_purchase_celulas: null,
  fetched_purchase: null,
  fetching_purchase: null,
  error_purchase: null,
  quotation_client_fetching: false,
  quotation_client_fetched: false,
  agent: null,
  xls: null,
  send_quote: {
    name: '',
    lastName: '',
    email: '',
    phone: '',
    confir: false,
    optionsId: '',
  },
  search_param_quotes: '',
  search_param_sale: '',
  search_param_quotes_loading: false,
  search_param_sale_loading: false,
  get_cotizacion_fetching: null,
  get_cotizacion_fetched: null,
  get_cotizacion_error: null,
  insureds_put_all_fetched:false,
  insureds_put_all_error:false,
  emit_card_fetching: false,
  emit_card_fetched: false,
  validate_type_payment: null,
  validate_type_insurance: null,
  validate_contact: null,
  validate_principal_beneficiary: null,
  validate_depents: null,
  extra_params: null,
  put_principal_option_fetched: false,
  put_principal_option_fetching: false,
  put_principal_option_error: false
};
const temp = JSON.parse(JSON.stringify(ini));

const initial_state = ini;

const quotation_stem_aux = {
  type: "",
  paymentTypee: "",
  dependents: [],
  legalGuardian: {
    name: "",
    lastName: "",
    phone: "",
    email: ""
  },
  contact: {
    name: "",
    lastName: "",
    phone: "",
    email: ""
  },
  beneficiary: {
    type: "",
    name: "",
    lastName: "",
    gender: "",
    birthDate: "",
    lab: null,
    residency: null,
  },
  billing: {
    id: false,
    name: "",
    lastName: "",
    document: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    country: ""
  },
  pay: {
    name: "",
    number: ["", "", "", ""],
    expiration: ["", ""],
    ccv: ""
  },
  insureds: [],
  answers: [],
  emit_card_fetching: false,
  emit_card_fetched: false,
  done_answers: false,
  done_pay: false,
  card: {
    feching: false,
    feched: false,
    result: null,
    card: null,
  },
  page_param_quotes: 0,
  page_param_sale: 0,
  extra_params: null,
  update_contact_quotation:false,
  changeEmail:false,
  get_programs_id_fetching: true,
  get_programs_id_fetched: false,
  get_programs_id_error: false,
  programs_id: [],
  programsValues: {},
  programsValuesUsuario: {},
  insurers_sale: [],
}

export default (state = ini, action) => {
  let items = [];
  switch (action.type) {
    case ActionsTypes.API_GET_PAYMENT_TYPES + ActionsTypes.FETCHED:
      return {
        ...state,
        payment_types: action.payload.data,
      }
    case ActionsTypes.API_GET_INSURERS_SALES + ActionsTypes.FETCHED:
      return {
        ...state,
        insurers_sale: action.payload.data,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_STEM + ActionsTypes.FETCHING: 
      return {
        ...state,
        fetched_insurers_stem: false,
        fetching_insurers_stem: true,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_STEM + ActionsTypes.FETCHED:
      const dataValues = action.payload.data;
      let programsValues = {}
      if(dataValues){
        for(let i = 0; i < dataValues.length; i++) {
          const dataValue = dataValues[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              programsValues[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValues,
        fetched_insurers_stem: true,
        fetching_insurers_stem: false,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched_insurers_stem: false,
        fetching_insurers_stem: true,
      }
    case ActionsTypes.API_GET_EDIT_INSURERS_USUARIO_STEM + ActionsTypes.FETCHED:
      const dataValuesUsuario = action.payload.data;
      let dataNewValuesUsuario = {}
      if(dataValuesUsuario){
        for(let i = 0; i < dataValuesUsuario.length; i++) {
          const dataValue = dataValuesUsuario[i];
          for(let j = 0; j < dataValue.programs.length; j++) {
            const program = dataValue.programs[j];
            if(program.active){
              dataNewValuesUsuario[program.id] = program.percentage
            }
          }
        }
      }
      return {
        ...state,
        programsValuesUsuario: dataNewValuesUsuario,
        fetched_insurers_stem: true,
        fetching_insurers_stem: false,
      }

    case ActionsTypes.API_GET_COACH_USUARIO_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_fetching: true,
        get_programs_fetched: false,
        get_programs_error: false,
      }
    case ActionsTypes.API_GET_COACH_USUARIO_STEM + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.length > 0) {
        return {
          ...state,
          get_programs_id_fetching: true,
          get_programs_id_fetched: false,
          get_programs_id_error: false,
          programs_id: action.payload.data,
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs_id: [],
        }
      }
    case ActionsTypes.API_GET_PROGRAM_TYPES + ActionsTypes.FETCHED:
      return {
        ...state,
        program_types: action.payload.data,
      }
    case ActionsTypes.API_GET_RELATIONSHIPS_TYPES + ActionsTypes.FETCHED:
      return {
        ...state,
        relationship_types: action.payload.data,
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM:
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          [action.payload.label]: action.payload.value
        },
        polizas_celulas_selected: []
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_CONTACT:
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          contact: {
            ...state.quotation_stem.contact,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_BENEFICIARY:
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          beneficiary: {
            ...state.quotation_stem.beneficiary,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_TUTOR:
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          legalGuardian: {
            ...state.quotation_stem.legalGuardian,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_ADD_DEPENT:
    let dependents = state.quotation_stem.dependents;
    if (dependents) {
      dependents.push({
        relationship:action.payload.type,
        name: "",
        lastName: "",
        gender:action.payload.gender,
        birthDate: ""
      })
    } else {
      dependents = [{
        relationship:action.payload.type,
        name: "",
        lastName: "",
        gender:action.payload.gender,
        birthDate: ""
      }]
    }
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        dependents
      }
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_SPLICE_DEPENT:
    dependents = state.quotation_stem.dependents;
    dependents.splice(action.payload, 1)
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        dependents
      }
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_DEPENT:
    dependents = state.quotation_stem.dependents;
    dependents[action.payload.index][action.payload.label] = action.payload.value
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        dependents
      }
    }
    case ActionsTypes.API_POST_COTIZACIONES_CELULAS_MADRE + ActionsTypes.FETCHING:
      return {
        ...state,
        programs_celulas_fetching: true,
        programs_celulas_fetched: false,
        programs_celulas_result: null,
        changeEmail:false,
      }
    case ActionsTypes.API_POST_COTIZACIONES_CELULAS_MADRE + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        let aux_filters = action.payload.data.filters;
        let aux_filters_array = [];
        for (const filter in aux_filters) {
          aux_filters_array.push( { code:filter ,aux:aux_filters[filter], type:"range"});
        }
        return {
          ...state,
          programs_celulas: action.payload.data.programs,
          id_quotation_celulas: action.payload.data.quote,
          filters_celulas: aux_filters_array,
          programs_celulas_result: action.payload.data,
          programs_celulas_fetching: false,
          programs_celulas_fetched: true,
          changeEmail:false,
        }
      }else{
        if(action.payload.data.changeEmail){
          return {
            ...state,
            changeEmail: true,
            programs_celulas_result: action.payload.data,
            programs_celulas_fetching: false,
            programs_celulas_fetched: false,
          }
        }else{
          let contact = action.payload.data.contact;
          return {
            ...state,
            update_contact_quotation: !!contact?    contact.phone?"phone":"email" : false,
            changeEmail:false,
            programs_celulas_result: action.payload.data,
            programs_celulas_fetching: false,
            programs_celulas_fetched: false,
          }
        }
        
      }

    case ActionsTypes.API_GET_PROGRAMS_STEM_ID + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_id_fetching: true,
        get_programs_id_fetched: false,
        get_programs_id_error: false,
        programs_id: [],
      }
    case ActionsTypes.API_GET_PROGRAMS_STEM_ID + ActionsTypes.FETCHED:
      if(action.payload.data && action.payload.data.success){
        return {
          ...state,
          get_programs_id_fetching: false,
          get_programs_id_fetched: true,
          get_programs_id_error: false,
          programs_id: action.payload.data.programs,
        }
      }else{
        return {
          ...state,
          get_programs_id_fetching: false,
          get_programs_id_fetched: false,
          get_programs_id_error: true,
          programs_id: [],
        }
      }
    case ActionsTypes.SET_PROGRAM_VALUE_STEM:
      return {
        ...state,
        programsValues:{
          ...action.payload
        }
      }
    case ActionsTypes.SET_PROGRAM_VALUE_STEM_USUARIO:
      return {
        ...state,
        programsValuesUsuario:{
          ...action.payload
        }
      }

    case ActionsTypes.API_GET_PROGRAMS_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        get_programs_fetching: true,
        get_programs_fetched: false,
        get_programs_error: false,
        programs: [],
      }
    
    case ActionsTypes.API_GET_PROGRAMS_STEM + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: true,
          get_programs_error: false,
          programs_: [],
        }
      }else{
        return {
          ...state,
          get_programs_fetching: false,
          get_programs_fetched: false,
          get_programs_error: true,
          programs: [],
        }
      }
        
      
    case ActionsTypes.ADD_REMOVE_PROGRAM_CELULAS:
      let {polizas_celulas_selected} = state;
      if (polizas_celulas_selected) {
        const index = polizas_celulas_selected.findIndex((poliza) => poliza.optionId === action.payload.optionId);
        if (index === -1 && polizas_celulas_selected.length < 4) {
          polizas_celulas_selected.push(action.payload)
        } else if (index !== -1) {
          polizas_celulas_selected.splice(index, 1)
        }
      } else {
        polizas_celulas_selected = [action.payload]
      }
      return {
        ...state,
        polizas_celulas_selected
      }
    case ActionsTypes.UPDATE_PROGRAMS_CELULAS:
      return {
        ...state,
        polizas_celulas_selected: [action.payload]
      }
      case ActionsTypes.API_GET_FILTER_BOX + ActionsTypes.FETCHING:
        return {
          ...state,
          filter_celulas_fetching: true,
          filter_celulas_fetched: false
        }
    case ActionsTypes.API_GET_FILTER_BOX + ActionsTypes.FETCHED:


      let filters = action.payload.data;
      let new_filters_stem = state.filters_celulas;
      for(let i = 0; i < filters.length; i++){
        const fil = filters[i];
        const index = new_filters_stem.findIndex((fi)=>fi.code === fil.code)
        if(index !== -1){
          if(fil.code !== 'coverage' && fil.code !== 'prima' ){
            new_filters_stem[index] = fil
          }
        }else{
          if(fil.code !== 'coverage' && fil.code !== 'prima' ){
            new_filters_stem.push(fil)
          }
        }
      }

      if(
        new_filters_stem && 
        new_filters_stem.length > 1 && 
        (
          new_filters_stem[new_filters_stem.length - 2].code === 'coverage' ||
          new_filters_stem[new_filters_stem.length - 2].code === 'prima'
        )
      ){
        new_filters_stem.reverse()
      }

      return {
        ...state,
        filters_celulas: new_filters_stem,
        filter_celulas_fetching: false,
        filter_celulas_fetched: true
      }
    case ActionsTypes.API_UPDATE_FILTER_BOX_UPDATE:
      let {label, value} = action.payload;
      let filters_celulas = state.filters_celulas;
      let index = filters_celulas.findIndex((filter) => filter.code === label)
      if(filters_celulas && filters_celulas[index] && filters_celulas[index].aux){
        if (label === "coverage" || label === "prima") {
          filters_celulas[index].aux = {
            min: value[0],
            max: value[1]
          }
        } else if (label === "payment_format") {
          filters_celulas[index].aux.default = value;
        } else if (label === "plan") {
          filters_celulas[index].aux.default = value;
        }
      }
    return {
      ...state,
      filters_celulas,
      programs_celulas_fetching: true,
      programs_celulas_fetched: false
    }

    case ActionsTypes.UPDATE_PROGRAMS_CELULAS_ALL:
      return {
        ...state,
        programs_celulas: action.payload,
      }
    case ActionsTypes.REMOVE_ALL_PROGRAM:
      return {
        ...state,
        polizas_celulas_selected:[]
      }
    case ActionsTypes.API_UPDATE_FILTER_BOX + ActionsTypes.FETCHING:
      let aditional = action.aditional
      if(aditional){
        return {
          ...state,
          aditional_filter:false,
          programs_celulas: [],
          programs_celulas_fetching: true,
          programs_celulas_fetched: true
        }
      }else{
        return {
          ...state,
          programs_celulas: [],
          programs_celulas_fetching: true,
          programs_celulas_fetched: true
        }
      }
      
    case ActionsTypes.API_UPDATE_FILTER_BOX + ActionsTypes.FETCHED:

      try {
        let new_filters_stem = state.filters_celulas;
        let aditional = action.aditional
        if(aditional){
          let filters = action.payload.data.filters;
          let {coverage, prima} = filters
          for(let i = 0; i < new_filters_stem.length; i++){
              const fil = new_filters_stem[i];
              if(fil.code == 'coverage' && coverage){
                new_filters_stem[i].aux = coverage
              }else if(fil.code == 'prima' && prima){
                new_filters_stem[i].aux = prima
              }
          }
          //console.log("filters", {aditional, new_filters_stem})
          return {
            ...state,
            programs_celulas: action.payload.data.programs,
            filters_celulas: new_filters_stem,
            programs_celulas_fetching: false,
            programs_celulas_fetched: true,
            aditional_filter: true,
          }
        }else{
          return {
            ...state,
            programs_celulas: action.payload.data.programs,
            programs_celulas_fetching: false,
            programs_celulas_fetched: true
          }
        }
      } catch (error) {
        return {
          ...state,
          programs_celulas: action.payload.data.programs,
          programs_celulas_fetching: false,
          programs_celulas_fetched: true
        }
      }
      
    case ActionsTypes.UPDATE_QUOTATION_STEM_CONTACT_ALL:
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        contact: action.payload
      }
    }
    case ActionsTypes.SEND_EMAIL_QUOTATION_STEM_API + ActionsTypes.FETCHING:
    return {
      ...state,
      send_email_stem_fetching: true,
      send_email_stem_fetched: false,
      send_email_stem_error: false,
    }
    case ActionsTypes.SEND_EMAIL_QUOTATION_STEM_API + ActionsTypes.FETCHED:
    if (action.payload.data.success) {
      return {
        ...state,
        send_email_stem_fetching: false,
        send_email_stem_fetched: true,
        send_email_stem_error: false,
      }
    }else{
      return {
        ...state,
        send_email_stem_fetching: false,
        send_email_stem_fetched: true,
        send_email_stem_error: true,
      }
    }
    case ActionsTypes.SEND_EMAIL_QUOTATION_STEM_RESET:
      return {
        ...state,
        send_email_stem_fetching: false,
        send_email_stem_fetched: false,
        send_email_stem_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHING:
      return {
        ...state,
        put_principal_option_fetching: true,
        put_principal_option_fetched: false,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.FETCHED:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: true,
        put_principal_option_error: false,
      }
    case ActionsTypes.PUT_QUOTATION_PRINCIPAL_OPTION + ActionsTypes.ERROR:
      return {
        ...state,
        put_principal_option_fetching: false,
        put_principal_option_fetched: false,
        put_principal_option_error: true,
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_CONTACT_API + ActionsTypes.FETCHING:
      return {
        ...state,
        contact_update_fetching: true,
        contact_update_fetched: false,
        contact_update_result: null,
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_CONTACT_API + ActionsTypes.FETCHED:
      return {
        ...state,
        contact_update_fetched: true,
        contact_update_fetching: false,
        contact_update_result: action.payload.data,
      }
    case ActionsTypes.RESET_FETCH_CONTACT:
      return {
        ...state,
        contact_update_fetched: false,
        insureds_put_fetched: false,
        insureds_put_all_fetched:false,
        post_answers_fetched: false,
        emit_card_fetched: false,
        send_email_stem_fetched:false,
        put_principal_option_fetched: false,
        put_principal_option_fetching: false,
        put_principal_option_error: false
      }
    case ActionsTypes.UPDATE_QUOTATION_STEM_BILLING_ALL:
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        billing: action.payload
      }
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_BILLING_API + ActionsTypes.FETCHING:
    return {
      ...state,
      billing_update_fetched: false,
      billing_update_fetching: true,
      billing_update_result: null,
      quotation_stem: {
        ...state.quotation_stem,
        billing: {
          id: false,
          name: "",
          lastName: "",
          document: "",
          address: "",
          city: "",
          zip: "",
          state: "",
          country: ""
        }
      }
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_BILLING_API + ActionsTypes.FETCHED:
    return {
      ...state,
      billing_update_fetched: true,
      billing_update_fetching: false,
      billing_update_result: action.payload.data,
      quotation_stem: {
        ...state.quotation_stem,
        billing: action.payload.data.info
      }
    }
    case ActionsTypes.RESET_FETCH_BILLING:
    return {
      ...state,
      billing_update_fetched: false,
    }
    case ActionsTypes.GET_QUOTATION_STEM_BILLING + ActionsTypes.FETCHED:
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        billing: action.payload.data[0]
      },
      done_pay: !!(action.payload.data[0] && action.payload.data[0].card && action.payload.data[0].card.number)
    }
    case ActionsTypes.PUT_QUOTATION_STEM_BILLING_API + ActionsTypes.FETCHING:
    return {
      ...state,
      billing_update_fetched: false,
      billing_update_fetching: true,
      billing_update_result: null,
    }
    case ActionsTypes.PUT_QUOTATION_STEM_BILLING_API + ActionsTypes.FETCHED:
    return {
      ...state,
      billing_update_fetched: true,
      billing_update_fetching: false,
      billing_update_result: action.payload.data,
    }
    case ActionsTypes.GET_QUOTATION_STEM_INSUREDS + ActionsTypes.FETCHED:
    return {
      ...state,
      insureds_get_fetched: true,
      quotation_stem: {
        ...state.quotation_stem,
        insureds: action.payload.data
      },
      venta_selected: {
        ...state.venta_selected,
        insureds: action.payload.data
      }
    }
    case ActionsTypes.PUT_STEM_ALL_INSUREDS:
      if(!!action.payload){
        return {
          ...state,
          insureds_put_all_fetched: true,
          insureds_put_all_error: false,
        }
      }else{
        return {
          ...state,
          insureds_put_all_fetched: false,
          insureds_put_all_error: true,
        }
      }
    case ActionsTypes.GET_QUOTATION_STEM_LEGAL_GUARDIAN + ActionsTypes.FETCHED:
      return {
        ...state,
        insureds_get_fetched: true,
        quotation_stem: {
          ...state.quotation_stem,
          legalGuardian: {
            ...action.payload.data[0],
            name: action.payload.data[0].name == "Pendiente"?"":action.payload.data[0].name
          }
        }
      }
    case ActionsTypes.PUT_QUOTATION_STEM_INSURED_API + ActionsTypes.FETCHING:
      return {
        ...state,
        insureds_put_fetched: false,
        insureds_put_fetching: true,
        insureds_put_result: null,
        /*quotation_stem:{
            ...state.quotation_stem,
            legalGuardian:action.payload.data[0]
        }*/
      }
    case ActionsTypes.RESET_UPDATE_CONTACT_QUOTATION:
      return {
        ...state,
        update_contact_quotation:false
      }
    case ActionsTypes.PUT_QUOTATION_STEM_INSURED_API + ActionsTypes.FETCHED:
    return {
      ...state,
      insureds_put_fetched: true,
      insureds_put_fetching: false,
      insureds_put_result: action.payload.data,
      /*quotation_stem:{
          ...state.quotation_stem,
          legalGuardian:action.payload.data[0]
      }*/
    }
    case ActionsTypes.FILTER_COTIZACION_STEM:
      return {
        ...state,
        cotizaciones_filter: action.payload
      }

    case ActionsTypes.FILTER_VENTA_STEM:
        return {
        ...state,
        ventas_filter: action.payload
        }

    case ActionsTypes.GET_QUOTATION_STEM_LABS + ActionsTypes.FETCHED:
    return {
      ...state,
      labs: action.payload.data,
    }
    case ActionsTypes.PUT_QUOTATION_STEM_LEGAL_GUARDIAN + ActionsTypes.FETCHING:
    return {
      ...state,
      legal_guardian_put_fetched: false,
      legal_guardian_put_fetching: true,
      legal_guardian_put_result: null,
    }
    case ActionsTypes.PUT_QUOTATION_STEM_LEGAL_GUARDIAN + ActionsTypes.FETCHED:
      return {
        ...state,
        legal_guardian_put_fetched: true,
        legal_guardian_put_fetching: false,
        legal_guardian_put_result: action.payload.data,
      }
    case ActionsTypes.API_GET_COTIZACIONES_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_quotes_loading: false,
      };
    case ActionsTypes.API_GET_VENTAS_STEM + ActionsTypes.FETCHED:
      return {
        ...state,
        ventas: action.payload.data.sales,
        ventas_filter: action.payload.data.sales,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.API_GET_VENTAS_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        fetched: false,
        fetching: true,
        search_param_sale_loading: false,
      };
    case ActionsTypes.API_GET_TRAVEL_TYPES_STEM + ActionsTypes.FETCHED:
      return {
        ...state,
        travel_types: action.payload.data,
        quotation: {
          ...state.quotation,
          emergency_contact: null
        }
      }
      case ActionsTypes.GET_QUOTATION_BY_ID + ActionsTypes.FETCHED:
        if(!!action.payload.data.quote){
          return {
            ...state,
            quotation_stem:{
              ...state.quotation_stem,
              ...action.payload.data.quote
            }
          }
        }else{
          return {
            ...state
          }
        }
    case ActionsTypes.RESET_COTIZACION_STEM:
      return {
        ...initial_state
      }
    case ActionsTypes.SEARCH_COTIZACION_STEM:
      return {
        ...state,
        search_param_quotes: action.payload.search,
        page_param_quotes:  action.payload.page,
        search_param_quotes_loading: true,
      }
    case ActionsTypes.SEARCH_SALE_STEM:
      return {
        ...state,
        search_param_sale: action.payload.search,
        page_param_sale:  action.payload.page,
        search_param_sale_loading: true,
      }
    case ActionsTypes.RESET_PAGINATION:
      return {
        ...state,
        search_param_sale: "",
        search_param_quotes: "",
        page_param_sale:  0,
        page_param_quotes: 0
      }
    case ActionsTypes.EXTRA_PARAMS:
      return {
        ...state,
        extra_params: action.payload,
      }
    case ActionsTypes.RESET_EXTRA_PARAMS:
      return {
        ...state,
        extra_params: null,
      }
    case ActionsTypes.VENTA_SELECT_STEM:
      return {
        ...state,
        venta_selected: action.payload
      }
    case ActionsTypes.API_GET_QUOTE_STATUSES_STEM + ActionsTypes.FETCHING:
      return {
        ...state,
        statuses: [],
        statuses_feching: true,
        statuses_feched: false,
      }
  case ActionsTypes.API_GET_SPECIAL_COVERAGE_STEM + ActionsTypes.FETCHED:
    return {
      ...state,
      specialCoverages: action.payload.data
    }
    case ActionsTypes.API_GET_QUOTE_STATUSES_STEM + ActionsTypes.FETCHED:
      let items = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      items.unshift({
        id: null,
        text: "Estado de la cotizacion"
      });
      return {
        ...state,
        statuses: items,
        statuses_feching: false,
        statuses_feched: true,
      }
    case ActionsTypes.API_GET_COTIZACIONES_STEM + ActionsTypes.FETCHED:
      return {
        ...state,
        cotizaciones: action.payload.data.quotes,
        cotizaciones_filter: action.payload.data.quotes,
        pagination: action.payload.data.pagination,
        fetched: true,
        fetching: false
      };
    case ActionsTypes.GET_QUOTATION_STEM_QUESTIONS + ActionsTypes.FETCHED:
    let done_answers = true;
    let data_questions = action.payload.data.items[0];
    for (let i = 0; i < data_questions.categories.length; i++) {
      let category = data_questions.categories[i];
      for (let j = 0; j < category.questions.length; j++) {
        let question = category.questions[j];
        let answer = question.answer;
        if ((answer == false || answer == 1) && answer !== "0") {
          done_answers = false
        } else if (answer == null) {
          done_answers = false
        }
      }
    }
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        done_answers,
      },
      questions: action.payload.data.items,
    }
    case ActionsTypes.POST_QUOTATION_STEM_QUESTIONS_ANSWERS + ActionsTypes.FETCHING:
      return {
        ...state,
        post_answers_fetched: false,
        post_answers_fetching: true,
        post_answers_result: null,
      }
    case ActionsTypes.UPDATE_QUOTE_ID:
      return {
        ...state,
        id_quotation_celulas: action.payload
      }
    case ActionsTypes.POST_QUOTATION_STEM_QUESTIONS_ANSWERS + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          quotation_stem: {
            ...state.quotation_stem,
            done_answers: true,
          },
          post_answers_fetched: true,
          post_answers_error:false,
          post_answers_fetching: false,
          post_answers_result: action.payload.data,
        }
      }else{
        return {
          ...state,
          quotation_stem: {
            ...state.quotation_stem,
            done_answers: false,
          },
          post_answers_fetched: false,
          post_answers_error: true,
          post_answers_fetching: false,
          post_answers_result: action.payload.data,
        }
      }
    
    case ActionsTypes.UPDATE_QUOTATION_STEM_PAY + ActionsTypes.FETCHING:
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        card: {
          ...state.quotation_stem.card,
          feching: true,
          feched: false,
          result: null,
        }
      },
      emit_card_fetching: true,
      emit_card_fetched: false
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_PAY + ActionsTypes.FETCHED:

    if(action.payload.data && action.payload.data.success){
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          card: {
            ...state.quotation_stem.card,
            feching: false,
            feched: true,
            result: action.payload.data,
          }
        },
        emit_card_fetching: false,
        emit_card_fetched: true,
        done_pay: true
      }
    }else{
      return {
        ...state,
        quotation_stem: {
          ...state.quotation_stem,
          card: {
            ...state.quotation_stem.card,
            feching: false,
            feched: true,
            result: action.payload.data,
          }
        },
        emit_card_fetching: false,
        emit_card_fetched: true,
        done_pay: false
      }
    }
    case ActionsTypes.UPDATE_QUOTATION_STEM_PAY2:
    return {
      ...state,
      quotation_stem: {
        ...state.quotation_stem,
        billing:{
          ...state.quotation_stem.billing,
          card:{
            number:"xxxx"
          }
        },
        pay: action.payload,
        done_pay: true
      }
    }
    case ActionsTypes.POST_EMIT_STEM_QUOTATION + ActionsTypes.FETCHING:
    return {
      ...state,
      emit_celulas_fetching: true,
      emit_celulas_fetched: false,
      result_purchase_celulas: {},
      emit_celulas_error:false
    }
    case ActionsTypes.POST_EMIT_STEM_QUOTATION + ActionsTypes.FETCHED:
    if (action.payload.data.success) {
      return {
        ...state,
        emit_celulas_fetching: false,
        emit_celulas_fetched: true,
        emit_celulas_error: false
      }
    } else {
      return {
        ...state,
        emit_celulas_fetching: false,
        emit_celulas_fetched: false,
        emit_celulas_error: true,
        result_purchase_celulas: action.payload.data,
      }
    }
    case ActionsTypes.POST_EMIT_STEM_QUOTATION + ActionsTypes.ERROR:
      return {
        ...state,
        emit_celulas_fetching: false,
        emit_celulas_fetched: false,
        emit_celulas_error: true, 
        result_purchase_celulas: action.payload.data,
      }
    
    case ActionsTypes.PUT_QUOTATION_STEM_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        put_options_celulas_fetching: false,
        put_options_celulas_fetched: true
      }
    case ActionsTypes.POST_GET_STEM_QUOTATION_OPTIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        post_get_options_celulas_fetching: false,
        post_get_options_celulas_fetched: true,
        polizas_celulas_selected: action.payload.data.programs
      }
    case ActionsTypes.SET_DATA_QUOTATION_STEM:
      return {
        ...state,
        id_quotation_celulas: action.payload.id,
        quotation_stem: {
          ...state.quotation_stem,
          insureds: action.payload.insureds,
          contact: action.payload && action.payload.contact? 
            action.payload.contact:
            {
                name: action.payload.name,
                lastName: action.payload.lastName,
                phone: action.payload.phone,
                email: action.payload.email
            },
          pay: {
            name: "",
            number: ["", "", "", ""],
            expiration: ["", ""],
            ccv: ""
          },
          done_pay: false,
        }
      }
    case ActionsTypes.SET_ERROR_COTIZADOR_STEM:
      let error = state.error;
      if(!error){
        error = {
          cotizador:{
            type: false,
            paymentType: false,
            contact:{
              firstName: false,
              lastName: false,
              email:false,
              phone:false
            },
            beneficiary:{
              name: false,
              lastName: false,
              birthDate: false,
              residency: false,
              residency2: false,
              gender: false
            }
          }
        }
      }
      if(action.payload.label == "contact" || action.payload.label == "beneficiary"){
        return {
          ...state,
          error:{
            ...error,
            cotizador:{
              ...error.cotizador,
              [action.payload.label]:{
                ...error.cotizador[action.payload.label],
                [action.payload.label2]:action.payload.value
              }
            }
          }
        }
      }else{
        return {
          ...state,
          error:{
            ...error,
            cotizador:{
              ...error.cotizador,
              [action.payload.label]:action.payload.value
            }
          }
        }
      }
      
    case ActionsTypes.VALIDATE_QUOTE_FORM_STEM:
      return {
        ...state,
        validate_type_payment: true,
        validate_type_insurance: true,
        validate_contact: true,
        validate_principal_beneficiary: true,
        validate_depents: true,
      }
    case ActionsTypes.OFF_VALIDATION_TYPE:
      let type = action.payload
      if(type === "type_payment"){
        return {
          ...state,
          validate_type_payment: false,
        }
      }else if(type === "type_insurance"){
        return {
          ...state,
          validate_type_insurance: false,
        }
      }else if(type === "contact"){
        return {
          ...state,
          validate_contact: false,
        }
      }else if(type === "principal"){
        return {
          ...state,
          validate_principal_beneficiary: false,
        }
      }else{
        return {
          ...state,
          validate_depents: false,
        }
      }
      case ActionsTypes.GET_COTIZACION + ActionsTypes.FETCHING:
        return {
          ...state,
          get_cotizacion_fetching: true,
          get_cotizacion_fetched: false,
          get_cotizacion_error: false,
        }
      case ActionsTypes.GET_COTIZACION + ActionsTypes.FETCHED:
        if (action.payload.data.success || (action.payload.data.quote && action.payload.data.quote.id)) {
          return {
            ...state,
            get_cotizacion_fetching: false,
            get_cotizacion_fetched: true,
            get_cotizacion_error: false,
            quotation_stem: {
              ...state.quotation_stem,
              ...action.payload.data.quote,
              insureds:state.quotation_stem.insureds
            },
            id_quotation_celulas:action.payload.data.quote.id,
          }
        }else{
          return {
            ...state,
            get_cotizacion_fetching: false,
            get_cotizacion_fetched: false,
            get_cotizacion_error: true,
          }
        }
    case ActionsTypes.EMAIL_API_GET_COTIZACION + ActionsTypes.FETCHING:
      return {
        ...state,
        get_cotizacion_fetching: true,
        get_cotizacion_fetched: false,
        get_cotizacion_error: false,
      }
    case ActionsTypes.EMAIL_API_GET_COTIZACION + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          get_cotizacion_fetching: false,
          get_cotizacion_fetched: true,
          get_cotizacion_error: false,
          quotation_stem: {
            ...quotation_stem_aux,
            ...action.payload.data.quote,
            contact:{
              firstName: action.payload.data.quote?action.payload.data.quote.firstName:"",
              lastName: action.payload.data.quote?action.payload.data.quote.lastName:"",
              email: action.payload.data.quote?action.payload.data.quote.email:"",
              phone: action.payload.data.quote?action.payload.data.quote.phone:"",
            }
          },
          id_quotation_celulas:action.payload.data.quote.id,
          //polizas_celulas_selected: action.payload.data.programs,
        }
      }else{
        return {
          ...state,
          get_cotizacion_fetching: false,
          get_cotizacion_fetched: false,
          get_cotizacion_error: true,
        }
      }

    default:
      return state;
    }
};

export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'

//API COTIZACIONES
export const API_GET_COTIZACIONES = "API_GET_COTIZACIONES"
export const API_GET_VENTAS = "API_GET_VENTAS"
export const API_GET_COTIZACION = "API_GET_COTIZACION"
export const SEARCH_COTIZACION = "SEARCH_COTIZACION"
export const SEARCH_SALE = "SEARCH_SALE"
export const API_GET_TRAVEL_TYPES = "API_GET_TRAVEL_TYPES"
export const CHANGE_VALUE_QUOTATION = "CHANGE_VALUE_QUOTATION"
export const UPDATE_FIRST_RANGE_DATE = "UPDATE_FIRST_RANGE_DATE"
export const UPDATE_ORIGIN = "UPDATE_ORIGIN"
export const UPDATE_DESTINATIONS = "UPDATE_DESTINATIONS"
export const ADD_INSURED = "ADD_INSURED"
export const REMOVE_INSURED = "REMOVE_INSURED"
export const REMOVE_INSURED_ERROR = "REMOVE_INSURED_ERROR"
export const ADD_DESTINATION = "ADD_DESTINATION"
export const REMOVE_DESTINATION = "REMOVE_DESTINATION"
export const UPDATE_RANGE_DATE = "UPDATE_RANGE_DATE"
export const UPDATE_CONTACT = "UPDATE_CONTACT"
export const API_GET_SPECIAL_COVERAGE = "API_GET_SPECIAL_COVERAGE"
export const UPDATE_INSURED = "UPDATE_INSURED"
export const API_POST_COTIZACIONES = "API_POST_COTIZACIONES"
export const API_POST_SEND_QUOTE = "API_POST_SEND_QUOTE"
export const RESET_SEND = "RESET_SEND"
export const RESET_SEND_RESULT = "RESET_SEND_RESULT"
export const API_POST_GET_COTIZACIONES = "API_POST_GET_COTIZACIONES"
export const SELECT_PROGRAM = "SELECT_PROGRAM"
export const API_GET_DOCUMENT_TYPES = "API_GET_DOCUMENT_TYPES"
export const API_POST_PURCHASE = "API_POST_PURCHASE"
export const RESET_REQUEST = "RESET_REQUEST"
export const ADD_POLIZA_COMPARAR = "ADD_POLIZA_COMPARAR"
export const REMOVE_POLIZA_COMPARAR = "REMOVE_POLIZA_COMPARAR"
export const COTIZACION_SELECT = "COTIZACION_SELECT"
export const RESET_PROGRAMS_SELECTED = "RESET_PROGRAMS_SELECTED"
export const VENTA_SELECT = "VENTA_SELECT"
export const REVERSE_PROGRAMS = "REVERSE_PROGRAMS"
export const RESET_COTIZACION = "RESET_COTIZACION"
export const GET_POLIZAS_COTIZACION  = "GET_POLIZAS_COTIZACION"
export const DELETE_COVERAGE = "DELETE_COVERAGE"
export const SELECT_COTIZACION = "SELECT_COTIZACION"
export const API_DOWNLOAD_VENTAS = "API_DOWNLOAD_VENTAS"
export const RESET_XLS = "RESET_XLS"
export const FILTER_COTIZACION = "FILTER_COTIZACION"
export const API_GET_INSURERS_QUOTES = "API_GET_INSURERS_QUOTES"
export const RESET_PURCHASE = "RESET_PURCHASE"
export const UPDATE_INSUREDS = "UPDATE_INSUREDS"
export const SAVE_SEND_QUOTE = "SAVE_SEND_QUOTE"
export const API_GET_QUOTE_STATUSES = "API_GET_QUOTE_STATUSES"
export const RESET_QUOTATION = "RESET_QUOTATION"
export const CHANGE_SEARCH_QUOTES = "CHANGE_SEARCH_QUOTES"
export const API_GET_PAYMENT_TYPES_VIAJE = "API_GET_PAYMENT_TYPES_VIAJE"
export const API_GET_PROGRAM_TYPES_VIAJE = "API_GET_PROGRAM_TYPES_VIAJE"
export const RESET_PAGINATION = "RESET_PAGINATION"
export const RESET_QUOTATION_EMIT = "RESET_QUOTATION_EMIT"
export const RESET_FETCH_CONTACT = "RESET_FETCH_CONTACT"
export const UPDATE_QUOTATION_CONTACT_API = "UPDATE_QUOTATION_CONTACT_API"
export const UPDATE_QUOTATION_CONTACT_ALL = "UPDATE_QUOTATION_CONTACT_ALL"
export const UPDATE_PROGRAMS = "UPDATE_PROGRAMS"
export const GET_QUOTATION_BILLING = "GET_QUOTATION_BILLING"
export const RESET_FETCH_BILLING = "RESET_FETCH_BILLING"
export const PUT_QUOTATION_BILLING_API = "PUT_QUOTATION_BILLING_API"
export const GET_QUOTATION_INSUREDS = "GET_QUOTATION_INSUREDS"
export const GET_QUOTATION_QUESTIONS = "GET_QUOTATION_QUESTIONS"
export const PUT_QUOTATION_INSURED_API = "PUT_QUOTATION_INSURED_API"
export const UPDATE_QUOTATION_BILLING_API = "UPDATE_QUOTATION_BILLING_API"
export const UPDATE_QUOTATION_BILLING_ALL = "UPDATE_QUOTATION_BILLING_ALL"
export const GET_QUOTATION_EMERGENCY_CONTACT = "GET_QUOTATION_EMERGENCY_CONTACT"
export const UPDATE_CONTACT_EMERGENCY = "UPDATE_CONTACT_EMERGENCY"
export const UPDATE_QUOTATION_CONTACT_EMERGENCY_API = "UPDATE_QUOTATION_CONTACT_EMERGENCY_API"
export const UPDATE_QUOTATION_CONTACT_EMERGENCY_ALL = "UPDATE_QUOTATION_CONTACT_EMERGENCY_ALL"
export const UPDATE_QUOTATION_PAY = "UPDATE_QUOTATION_PAY"
export const EXTRA_PARAMS = "EXTRA_PARAMS"
export const RESET_EXTRA_PARAMS = "RESET_EXTRA_PARAMS"
export const PUT_TRAVEL_ALL_INSUREDS = "PUT_TRAVEL_ALL_INSUREDS"
export const CLEAR_ERROR_BILLING = "CLEAR_ERROR_BILLING"
export const VIAJE_PUT_QUOTATION_OPTIONS = "VIAJE_PUT_QUOTATION_OPTIONS"
export const UPDATE_QUOTE_ID = "UPDATE_QUOTE_ID"
export const GET_QUOTATION_BY_ID = "GET_QUOTATION_BY_ID"
export const POST_GET_VIAJE_QUOTATION_OPTIONS = "POST_GET_VIAJE_QUOTATION_OPTIONS"
export const SET_ERROR_COTIZADOR_VIAJE = "SET_ERROR_COTIZADOR_STEM"
export const UPDATE_FILTER_SEARCH_BOX = "UPDATE_FILTER_SEARCH_BOX"
export const API_GET_FILTER_BOX = "API_GET_FILTER_BOX"
export const GET_COTIZACION = "GET_COTIZACION"
export const PUT_QUOTATION_PRINCIPAL_OPTION = "PUT_QUOTATION_PRINCIPAL_OPTION"
export const RESET_UPDATE_CONTACT_QUOTATION = "RESET_UPDATE_CONTACT_QUOTATION"
export const VALIDATE_QUOTE_FORM = "VALIDATE_QUOTE_FORM"
export const OFF_VALIDATION_TYPE = "OFF_VALIDATION_TYPE"
export const GET_QUOTATION_SALE_BY_ID = "GET_QUOTATION_SALE_BY_ID"
export const GET_QUOTATION_SALE_BILLING = "GET_QUOTATION_SALE_BILLING"
export const GET_QUOTATION_SALE_EMERGENCY_CONTACT = "GET_QUOTATION_SALE_EMERGENCY_CONTACT"
export const GET_SALE_BY_ID = "GET_SALE_BY_ID"
export const API_GET_PROGRAMS_VIAJE = "API_GET_PROGRAMS_VIAJE"
export const SET_PROGRAM_VALUE_VIAJE = "SET_PROGRAM_VALUE_VIAJE"
export const API_GET_EDIT_INSURERS_VIAJE = "API_GET_EDIT_INSURERS_VIAJE"
export const SET_PROGRAM_VALUE_VIAJE_USUARIO = "SET_PROGRAM_VALUE_VIAJE_USUARIO"
export const API_GET_EDIT_INSURERS_USUARIO_VIAJE = "API_GET_EDIT_INSURERS_USUARIO_VIAJE"
export const API_GET_COACH_USUARIO_VIAJE = "API_GET_COACH_USUARIO_VIAJE"
export const API_GET_INSURERS_SALES = "API_GET_INSURERS_SALES"
export const RESET_BILLING_RESULT = "RESET_BILLING_RESULT"
export const RESET_FETCH_INSUREDS_PUT = "RESET_FETCH_INSUREDS_PUT"
export const VIAJE_RESET_QUOTATION = "VIAJE_RESET_QUOTATION"
export const CLEAR_ERROR_FECHING_VIAJE = "CLEAR_ERROR_FECHING_VIAJE"

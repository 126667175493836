import * as ActionsTypes from "./types";
import {FILE_URL_BASE} from "../../config/constants";

const ini = {
  tab_menu_active: '',
  countries: [],
  states:[],
  states_us:[],
  beneficiaries_b2c:[],
  sectors:[],
  roles:[],
  configs: {
    s3: FILE_URL_BASE
  },
  user: {
    profile: null,
    profile_fetching: false,
    profile_fetched: false,
    profile_success: false,
  },
  states_us_fetched:false,
  validDomine: {
    fetching: false,
    fetched: false,
    result: false,
    error: false,
  },
  home:{
    insurers: [],
    insurers_fetching: false,
    insurers_fetched: false,
  }
};

export default (state = ini, action) => {
  switch (action.type) {
    case ActionsTypes.CHANGE_TAB_MENU:
      return {
        ...state,
        tab_menu_active: action.payload
      };
    case ActionsTypes.API_GET_COUNTRIES + ActionsTypes.FETCHED:
      return {
        ...state,
        countries: action.payload.data,
      };
    case ActionsTypes.RESET_STATES:
      {
        return {
          ...state,
          states:[]
        }
      };
    case ActionsTypes.API_GET_STATES + ActionsTypes.FETCHED:
      return {
        ...state,
        states: action.payload.data,
      };
      case ActionsTypes.API_GET_STATES_US  + ActionsTypes.FETCHED:
        return {
          ...state,
          states_us: action.payload.data,
        };
    case ActionsTypes.API_GET_CONFIGS + ActionsTypes.FETCHED:
      return {
        ...state,
        configs: action.payload.data,
      };


    case ActionsTypes.API_GET_ROLES + ActionsTypes.FETCHED:
      return {
        ...state,
        roles: action.payload.data,
      };

    case ActionsTypes.API_GET_BENEFICIARIES_B2C + ActionsTypes.FETCHED:
      return {
        ...state,
        beneficiaries_b2c: action.payload.data,
      };

    case ActionsTypes.API_GET_SECTORS + ActionsTypes.FETCHED:
      return {
        ...state,
        sectors: action.payload.data,
      };

    case ActionsTypes.API_GET_PROFILE + ActionsTypes.FETCHING:
      return {
        ...state,
        user: {
          ...state.user,
          profile_fetching: true,
          profile_fetched: false,
          profile_success: false,
        }
      }
    case ActionsTypes.API_GET_PROFILE + ActionsTypes.FETCHED:
      return {
        ...state,
        user: {
          ...state.user,
          profile: action.payload.data,
          profile_fetching: false,
          profile_fetched: true,
          profile_success: true,
        }
      }
    case ActionsTypes.RESET_SUCCESS_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          profile_success: false,
        }
      }
    case ActionsTypes.API_POST_VALID_DOMINE + ActionsTypes.FETCHING:
      return {
        ...state,
        validDomine: {
          ...state.validDomine,
          fetching: true,
          fetched: false,
        }
      }
    case ActionsTypes.API_POST_VALID_DOMINE + ActionsTypes.FETCHED:
      return {
        ...state,
        validDomine: {
          fetching: false,
          fetched: true,
          result: action.payload.data,
          error: false,
        }
      }
    case ActionsTypes.API_POST_VALID_DOMINE + ActionsTypes.ERROR:
      return {
        ...state,
        validDomine: {
          fetching: false,
          fetched: true,
          result: action.payload.data,
          error: true,
        }
      }

    case ActionsTypes.API_GET_PUBLIC_INSURERS + ActionsTypes.FETCHING:
      return {
        ...state,
        home: {
          ...state.home,
          insurers_fetching: true,
          insurers_fetched: false,
        }
      }
    case ActionsTypes.API_GET_PUBLIC_INSURERS + ActionsTypes.FETCHED:
      return {
        ...state,
        home: {
          ...state.home,
          insurers_fetching: false,
          insurers_fetched: true,
          insurers: action.payload.data,
        }
      }
    default:
      return state;
  }
};

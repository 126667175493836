import * as ActionsTypes from "./types";

const ini = {
  users: [],
  users_all:[],
  users_selected: [],
  users_fetching: false,
  users_fetched: false,
  user_crete_fetched:false,
  user_crete_success: false,
  user_crete_message: null,
  user_crete_fetching:null,
  user_insurers_fetching:null,
  user_insurers_fetched:null,
  test:'',
  select_user:{},
  crear_usuario_empresa: null,
  user:{
    name: '',
    lastName: '',
    email: '',
    phone: '',
    area: '',
    position: '',
    primaryContact: '',
    password: '',
    passwordConfirmation: '',
    rolId: '',
    empresaId: '',
    avatar: '',
    countries:[],
    sector:{},
    insurers :[]
  },
  filters: {
    countries: [],
    cities: [],
    statuses: [
      {
        id: null,
        text: "Estado de usuario"
      },
      {
        id: 1,
        text: "Activo"
      },
      {
        id: 0,
        text: "Inactivo"
      },
    ],
    roles: []
  },
  admins: [],
  smart_coach: [],
  user_select_id: null,
  user_select_id_fetching: null,
  user_select_id_fetched: null,
  user_select_id_error: null,
  feching_insurers: false,
  user_commissions_fetching: true,
  user_commissions_fetched: false,
  user_commissions_result: null,
  user_commissions_error: false,
  deleted_users: false,
  programs_comision_fetching:null,
  programs_comision_fetched:null,
  upload_user_fetching: false,
  upload_user_fetched: false,
  upload_user_error:false,
  upload_user_list_error: [],
  sectors_coach: []
};

const temp_user = JSON.parse(JSON.stringify(ini));

export default (state = ini, action) => {
  switch (action.type) {
    case ActionsTypes.USERS_INITIAL:
      return {
        users: temp_user.users,
        users_fetching: false,
        users_fetched: false,
        user_crete_fetched:false,
        user_crete_success: false,
        user_crete_message: null,
        user_crete_fetching:null,
        user_insurers_fetching:null,
        user_insurers_fetched:null,
        test:'',
        select_user: {},
        user:{
          name: '',
          lastName: '',
          email: '',
          phone: '',
          area: '',
          position: '',
          primaryContact: '',
          password: '',
          passwordConfirmation: '',
          rolId: '',
          empresaId: '',
          avatar: '',
          sector:{},
          countries:[],
          insurers :[]
        },
        filters: temp_user.filters,
        admins: [],
        empresa_usuario: !!state.empresa_usuario?state.empresa_usuario:{}
      }
    case ActionsTypes.UPDATE_CREATE_USER_FECHED:
      return {
        ...state,
        ...action.payload
      }
    case ActionsTypes.INFO_USER:
      return  {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case ActionsTypes.API_POST_UPLOAD_USER + ActionsTypes.FETCHING:
      return {
        ...state,
        upload_user_fetching: true,
        upload_user_fetched: false,
        upload_user_error:false,
        upload_user_list_error:[]
      }
    case ActionsTypes.API_POST_UPLOAD_USER + ActionsTypes.FETCHED:
      if(action.payload.data.success){
        return {
          ...state,
          upload_user_fetching: false,
          upload_user_fetched: true,
          upload_user_error:false,
          upload_user_list_error:[]
        }
      }else{
        return {
          ...state,
          upload_user_fetching: false,
          upload_user_fetched: false,
          upload_user_error:true,
          upload_user_list_error: action.payload.data.messages
        }
      }
    case ActionsTypes.RESET_USER:
      return  {
        ...state,
        user_crete_fetching:null,
        user_crete_fetched:null
      }

    case ActionsTypes.RESET_PASSWORD_FETCH:
      return {
        ...state,
        user_password_fetching: null,
        user_password_fetched: null,
      }
    case ActionsTypes.CREATE_USER_CHANGE_EMPRESA:
      return  {
        ...state,
        crear_usuario_empresa:action.payload,
        feching_insurers: false,
      }
    case ActionsTypes.SELECT_USER:
      return {
        ...state,
        select_user: action.payload
      }
    case ActionsTypes.API_PUT_USER + ActionsTypes.FETCHING:
      return {
        ...state,
        user_crete_fetching: true,
        user_crete_fetched: false
      }
    case ActionsTypes.API_PUT_USER + ActionsTypes.FETCHED:
      return {
        ...state,
        user_crete_fetching: false,
        user_crete_fetched: true
      }
    case ActionsTypes.API_GET_EMPRESA_USUARIO + ActionsTypes.FETCHED:
      return {
        ...state,
        empresa_usuario: action.payload.data,
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS + ActionsTypes.FETCHING:
      return {
        ...state,
        user_commissions_fetching: true,
        user_commissions_fetched: false
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS + ActionsTypes.FETCHED:
      return {
        ...state,
        user_commissions_fetching: false,
        user_commissions_fetched: true,
        user_commissions_result: action.payload.data,
        user_commissions_error: false
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS + ActionsTypes.ERROR:
      return {
        ...state,
        user_commissions_fetching: false,
        user_commissions_fetched: true,
        user_commissions_result: action.payload.data,
        user_commissions_error: true
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS_SECTORS + + ActionsTypes.FETCHING:
      return {
        ...state,
        user_commissions_sectors_fetching: true,
        user_commissions_sectors_fetched: false,
        user_commissions_sectors_result: null,
        user_commissions_sectors_error: false
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS_SECTORS + ActionsTypes.FETCHED:
      return {
        ...state,
        user_commissions_sectors_fetching: false,
        user_commissions_sectors_fetched: true,
        user_commissions_sectors_result: action.payload.data,
        user_commissions_sectors_error: false
      }
    case ActionsTypes.API_PUT_USER_COMMISSIONS_SECTORS + ActionsTypes.ERROR:
      return {
        ...state,
        user_commissions_sectors_fetching: false,
        user_commissions_sectors_fetched: true,
        user_commissions_sectors_result: action.payload.data,
        user_commissions_sectors_error: true
      }
    case ActionsTypes.RESET_UPDATE_COMMISSIONS :
      return {
        ...state,
        user_commissions_fetching: false,
        user_commissions_fetched: false,
        user_commissions_result: null,
        user_commissions_error: false
      }
    case ActionsTypes.API_GET_INSURER_USUARIO + ActionsTypes.FETCHING:
      return {
        ...state,
        user_insurers_fetching: true,
        user_insurers_fetched: false,
        feching_insurers: false,
      }
    case ActionsTypes.API_GET_INSURER_USUARIO + ActionsTypes.FETCHED:
        let insurers = action.payload.data.map((item) => {
          return {
            ...item,
            state: false,
            percentage: null,
            programs: item.programs.map((program) => {
              return {
                ...program,
                state: false,
                percentage: null,
              }
            })
          }
        })
        return {
          ...state,
          user_insurers_fetching: false,
          user_insurers_fetched: true,
          feching_insurers: true,
          insurers:action.payload.data,
          user:{
            ...state.user,
            insurers,
          }
        }
    case ActionsTypes.API_GET_SECTORS_COACH + ActionsTypes.FETCHED:
      return {
        ...state,
        sectors_coach: action.payload.data?action.payload.data:[]
      }

    case ActionsTypes.SET_INSURER_USUARIO:
      return {
        ...state,
        user_insurers_fetching: false,
        user_insurers_fetched: true,
        programs_comision_fetched: null,
        programs_comision_fetching: null,
        feching_insurers: false,
        insurers: action.payload,
        user:{
          ...state.user,
          insurers: action.payload,
        }
      }
    case ActionsTypes.API_GET_USERS + ActionsTypes.FETCHING:
      return {
        ...state,
        users_fetching: true,
        users_fetched: false,

      }
    case ActionsTypes.API_GET_USERS + ActionsTypes.FETCHED:
      return {
        ...state,
        users_fetching: false,
        users_fetched: true,
        users: action.payload.data,
        users_all: action.payload.data,
      }

    case ActionsTypes.FILTER_USERS:
      return {
        ...state,
        empresas_filter: action.payload,
      }

    case ActionsTypes.USER_SEARCH:
      let retorno = [];
      let search = action.payload;
      if(state.users_all && state.users_all.length > 0){
        state.users_all.forEach(usuario => {
          console.log(usuario)
          if (
            //(typeof usuario.empresa.businessName === 'string' &&  usuario.empresa.businessName.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.name === 'string' && usuario.name.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.phone === 'string' && usuario.phone.toUpperCase().includes(search.toUpperCase()) ) ||
            (typeof usuario.rol.name === 'string' && usuario.rol.name.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.lastName === 'string' && usuario.lastName.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.email === 'string' && usuario.email.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.area === 'string' && usuario.area.toUpperCase().includes(search.toUpperCase())) ||
            (typeof usuario.position === 'string' && usuario.position.toUpperCase().includes(search.toUpperCase()))
          ) {
            retorno.push(usuario)
          }
        });
      }
      return {
        ...state,
        users: retorno
      }

    case ActionsTypes.API_GET_ADMINS + ActionsTypes.FETCHING:
      return {
        ...state,
        admins: [],
      }
    case ActionsTypes.API_GET_ADMINS + ActionsTypes.FETCHED:
      return {
        ...state,
        admins: action.payload.data,
      }

    case ActionsTypes.API_GET_SMART_COACH + ActionsTypes.FETCHING:
      return {
        ...state,
        smart_coach: [],
      }
    case ActionsTypes.API_GET_SMART_COACH + ActionsTypes.FETCHED:
      return {
        ...state,
        smart_coach: action.payload.data,
      }

    case ActionsTypes.UPDATE_SELECT_INSURERS:
      return {
        ...state,
        select_user:{
          ...state.select_user,
          insurers: action.payload
        }
      }
    case ActionsTypes.UPDATE_INSUREDS_USER:
      return {
        ...state,
        user:{
          ...state.user,
          insurers: action.payload
        }
      }
    case ActionsTypes.API_POST_USERS + ActionsTypes.FETCHING:
      return {
        ...state,
        user_crete_fetching:true,
        user_crete_fetched:false,
        id_user_create: null,
      }
    case ActionsTypes.API_POST_USERS + ActionsTypes.FETCHED:
      const res = action.payload.data;
      return {
        ...state,
        user_crete_fetching:false,
        user_crete_fetched:true,
        id_user_create: res.id,
        user_crete_success: res.success,
        user_crete_message: res.message,
      }
    case ActionsTypes.API_USERS_FILTERS_COUNTRIES + ActionsTypes.FETCHED:
      let countries = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.text,
          iso3: item.iso3
        };
      })
      countries.unshift({
        id: null,
        text: "Pais"
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          countries: countries,
        }
      }
    case ActionsTypes.API_USERS_FILTERS_CITIES + ActionsTypes.FETCHED:
      let cities = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.text
        };
      })
      cities.unshift({
        id: null,
        text: "Provincia - estado"
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          cities: cities,
        }
      }
    case ActionsTypes.API_USERS_FILTERS_ROLES + ActionsTypes.FETCHED:
      let roles = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      roles.unshift({
        id: null,
        text: "Rol de usuario"
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          roles,
        }
      }
    case ActionsTypes.API_PUT_USER_PASSWORD + ActionsTypes.FETCHING:
      return {
        ...state,
        user_password_fetching:true,
        user_password_fetched:false
      }
    case ActionsTypes.API_PUT_USER_PASSWORD + ActionsTypes.FETCHED:
      return {
        ...state,
        user_password_fetching:false,
        user_password_fetched:true
      }
    case ActionsTypes.API_GET_USER + ActionsTypes.FETCHING:
      return {
        ...state,
        user_select_id_fetching: true,
        user_select_id_fetched: false,
        user_select_id_error: false,
      }
    case ActionsTypes.API_GET_USER + ActionsTypes.FETCHED:
      return {
        ...state,
        user_select_id_fetching: false,
        user_select_id_fetched: true,
        user_select_id_error: true,
        select_user: action.payload.data,
      }
    case ActionsTypes.API_GET_USER + ActionsTypes.ERROR:
      return {
        ...state,
        user_select_id_fetching: false,
        user_select_id_fetched: true,
        user_select_id_error: true,
        select_user: null,
      }
    case ActionsTypes.API_GET_PROGRAMS_COMISION + ActionsTypes.FETCHING:
      return {
        ...state,
        programs_comision_fetching:true,
        programs_comision_fetched:false
      }
    case ActionsTypes.API_GET_PROGRAMS_COMISION + ActionsTypes.FETCHED:
      return {
        ...state,
        programs_comision_fetching:false,
        programs_comision_fetched:true,
        select_user: {
          ...state.select_user,
          insurers: [...action.payload.data],
        }
      }
    case ActionsTypes.USERS_SELECT_ID:
      return {
        ...state,
        user_select_id: action.payload
      }
    case ActionsTypes.API_DELETE_USER:
      return {
        ...state,
        deleted_users:true
      }
    case ActionsTypes.API_EXPORT_USER + ActionsTypes.FETCHED:
      return {
        ...state
      }
    case ActionsTypes.SET_SECTOR_USUARIO:
      return {
        ...state,
        user: {
          ...state.user,
          sector: action.payload
        }
      }
    default:
      return state;
  }
};

import * as ActionsTypes from "./types";
import exec from "../exec";
import Axios from "axios";

export const postAuth = data => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_POST_LOGIN,
    Axios({ method: 'post', url: '/login-panel', data })
  );
}

export const setAuthData = data => {
  return {
    type: ActionsTypes.SET_AUTH_DATA,
    payload: data
  }
}

export const setAffiliateLink = (affiliateLink) => {
  return {
    type: ActionsTypes.SET_AFFILIATE_LINK,
    payload: affiliateLink
  }
}
export const setAuthData2 = data => {
  return {
    type: ActionsTypes.SET_AUTH_DATA2,
    payload: data
  }
}

export const resetStatusAuth = () => {
  return {
    type: ActionsTypes.RESET_STATUS_AUTH,
    payload: null
  }
}

export const validateToken = token =>  dispatch => {
  exec(
    dispatch,
    ActionsTypes.VALIDATE_TOKEN,
    Axios.get(`/b2c/auth/valid?token=${token}` )
  );
}

export const refreshToken = refreshToken =>  dispatch => {
  exec(
    dispatch,
    ActionsTypes.REFRESH_TOKEN,
    Axios.post(`/b2c/auth/refresh`,{refreshToken})
  );
}

export const resetPassword = data => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_POST_RESET_PASSWORD,
    Axios({ method: 'post', url: '/reset-password', data })
  );
}

export const restorePassword = data => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_POST_RESTORE_PASSWORD,
    Axios({ method: 'post', url: '/restore-password', data })
  );
}

export const logout = () => {
  return {
    type: ActionsTypes.AUTH_LOGOUT,
  }
}

export const initialResetPassword = () => {
  return {
    type: ActionsTypes.AUTH_INITIAL_RESET_PASSWORD,
  }
}
export const initialRestorePassword = () => {
  return {
    type: ActionsTypes.AUTH_INITIAL_RESTORE_PASSWORD,
  }
}


export const reset = () => ({
  type: ActionsTypes.RESET
});

export const validToken = data => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_POST_VALID_TOKEN,
    Axios.post('/valid-reset-token', data )
  );
}

export const getProfileB2c = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.GET_PROFILE_B2C,
    Axios({ method: 'get', url: '/b2c/profile' })
  );
}

export const resetProfile = () => ({
  type: ActionsTypes.RESET_PROFILE
});

import { combineReducers } from "redux";

import authReducer from "./authModule/reducer";
import viajeReducer from "./viajeModule/reducer";
import empresaReducer from "./empresaModule/reducer";
import usuarioReducer from "./userModule/reducer";
import generalReducer from "./generalModule/reducer";
import homeReducer from "./homeModule/reducer";
import saludReducer from "./saludModule/reducer";
import stemReducer from "./stemModule/reducer"

const allReducers = combineReducers({
  Auth: authReducer,
  Viaje: viajeReducer,
  Empresa: empresaReducer,
  Usuario: usuarioReducer,
  General:generalReducer,
  Home: homeReducer,
  Salud: saludReducer,
  Stem: stemReducer,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case "LOG_OUT":
      state = undefined;
      break;
    default:
      break;
  }
  return allReducers(state, action);
};

export default rootReducer;

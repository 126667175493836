import * as AuthActions from "../redux/authModule/action";

export const FILE_URL_BASE = "https://staging-smart-and-smart.s3.amazonaws.com";
export const DOMINE =
  (window.location.hostname === "localhost" ? "http" : "https") +
  "://" +
  window.location.hostname;
// export const DOMINE = 'https://staging-b2b.smartandsmart.net';
export const PATH_DEVELOMENT = "https://dev-api.kerango.com/";
export const DOMINE_B2C = "https://dev.kerango.com";
export const ENVIRONMENT = "dev";
export const AGENTE = "Agente";
export const SUPER_ADMIN = "Super Admin";

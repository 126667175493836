import axios from 'axios';
import { store } from './redux/store';
import * as ActionTypes from "./redux/authModule/types"

axios.interceptors.response.use(null,  (error)=> {
        //catches if the session ended!
        if ( error.response.status === 401) {
            console.log("EXPIRED TOKEN");
            store.dispatch({type:ActionTypes.UNAUTHORIZED})
            window.location.replace("/");
        }
        return Promise.reject(error);
    });

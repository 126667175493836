import * as ActionsTypes from "./types";
import exec from "../exec";
import axios from "axios";

export const updateCompany = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS,
    payload: {label, value}
  }
}

export const updateSectorsCompany = (value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS_SECTORS,
    payload: value
  }
}

export const updateEditSectorsCompany = (value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS_SECTORS,
    payload: value
  }
}

export const initEditEmpresas = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS_INITIAL,
    payload: {
      label, value
    }
  }
}

export const updateEditCompany = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS,
    payload: {label, value}
  }
}

export const updateAdmin = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS_ADMIN,
    payload: {label, value}
  }
}

export const updateEditAdmin = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS_ADMIN,
    payload: {label, value}
  }
}

export const updateConfigs = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS_CONFIGS,
    payload: {label, value}
  }
}

export const updateEditConfigs = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS_CONFIGS,
    payload: {label, value}
  }
}

export const updateFiles = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS_FILES,
    payload: {label, value}
  }
}

export const updateEditFiles = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_EDIT_EMPRESAS_FILES,
    payload: {label, value}
  }
}

export const updateInfo = (label, value) => {
  return {
    type: ActionsTypes.UPDATE_CREATE_INFO,
    payload: {label, value}
  }
}

export const initEmpresas = () => {
  return {
    type: ActionsTypes.UPDATE_CREATE_EMPRESAS_INITIAL,
    payload: {}
  }
}

export const getEmpresasTypes = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_EMPRESAS_TYPES,
    axios.get("/v1/companies-type")
  )
}

export const getFiltersCountries = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_EMPRESAS_FILTERS_COUNTRIES,
    axios.get("/v1/countries")
  )
}

export const getFiltersCities = (country) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_EMPRESAS_FILTERS_CITIES,
    axios.get("/v1/countries/states" + (country && country.id !== null ? "?country="+country.iso3 : ""))
  )
}

// Endpoint para obtener todas las empresas dado un filtro
// action creator que retorna una función (debido a que está utilizando Redux Thunk)
export const getEmpresas = (filters) => dispatch => {
  let params = [];
  let query = '';
  if(filters){
    for(const key in filters){
      const value = filters[key];
      if(value !== null){
        params.push(`${key}=${value}`);
      }
    }
  }
  if(params.length > 0){
    query = "?"+params.join("&")
  }
  exec(
    dispatch,
    ActionsTypes.API_GET_EMPRESAS,
    axios.get("/v1/managers"+query)   // uso de axios para realizar peticion get
  )
}

export const resetTarjetaConst = () => {
  return {
    type: ActionsTypes.RESET_TARJETA_CONST,
    payload: null
  }
}


// new action for filtering users
export const filters = (value) => {
  return {
    type: ActionsTypes.FILTER_EMPRESAS,
    payload: value
  }
}

export const getInsurers = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_INSURERS,
    axios.get("/v1/insurers/programs")
  )
}

export const getSectors = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_SECTORS,
    axios.get("/v1/sectors")
  )
}

export const getEmpresa = (id) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_EMPRESA,
    axios.get("/v1/managers/"+id)
  )
}

export const getEditInsurers = (empresa) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_EDIT_INSURERS,
    axios.get("/v1/insurers/programs-commissions?empresaId="+empresa.id)
  )
}

export const getCountries = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_COUNTRIES_CREATE_EMPRESAS,
    axios.get("/v1/countries")
  )
}
export const getCities = (country) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_CITIES_CREATE_EMPRESAS,
    axios.get("/v1/countries/states?country=" + country.id)
  )
}
export const getFiscalCities = (country) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_FISCAL_CITIES_CREATE_EMPRESAS,
    axios.get("/v1/countries/states?country=" + country)
  )
}
export const createEmpresa = (createEmpresa) => dispatch => {
  console.log(createEmpresa);
  exec(
    dispatch,
    ActionsTypes.API_POST_CREATE_EMPRESAS,
    axios.post(`/v1/managers`,
      createEmpresa,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  )
}

export const updateEmpresaSaludViajeStem = (id, data) =>dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_POST_UPDATE_EMPRESA_SALUD,
    axios.put(`/v1/managers/${id}/commissions`,
      data,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  )
}
export const editEmpresa = (empresa) => dispatch => {
  console.log('put empresa edit');
  console.log(empresa);
  exec(
    dispatch,
    ActionsTypes.API_PUT_EDIT_EMPRESAS,
    axios.put(`/v1/manager`,
      empresa,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
  )
}
export const active = (empresaId,active) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_PATCH_ACTIVE_EMPRESAS,
    axios.patch(`/v1/manager`,
      {
        companyId: empresaId,
        active: active
      },
    )
  )
}

export const activeEmpresas = (empresaIds,active) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_PATCH_ACTIVE_EMPRESAS,
    axios.put(`/v1/manager/delete`,
      {
        companyId: empresaIds,
        active
      },
    )
  )
}

export const selectEmpresa = (empresa) => {
  return {
    type: ActionsTypes.EMPRESAS_SELECT,
    payload:empresa
  }
}
export const selectEmpresaId = (empresaId) => {
  return {
    type: ActionsTypes.EMPRESAS_SELECT_ID,
    payload:empresaId
  }
}
//Actualizar Cliente Id de IMG o TokioMarine---------------------------------------------
//client_id={'img':{'value':''}
export const updateClientId = (client_id) => {
  return {
    type: ActionsTypes.UPDATE_CLIENT_ID_COMPANIES,
    payload:client_id
  }
}

export const updateClientIdEdit = (client_id) => {
  return {
    type: ActionsTypes.UPDATE_CLIENT_ID_COMPANIES_EDIT,
    payload:client_id
  }
}
export const updateClientIdClear= () => {
  return {
    type: ActionsTypes.UPDATE_CLIENT_ID_COMPANIES_CLEAR,
    payload:{}
  }
}

export const updateEmpresaSelect = (empresaId) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_UPDATE_EMPRESAS_SELECT,
    axios.get("/v1/managers/"+empresaId)
  )
}

export const updateInsurers = (edit, value) => {
  return {
    type: edit ? ActionsTypes.UPDATE_EDIT_EMPRESAS_INSURERS : ActionsTypes.UPDATE_CREATE_EMPRESAS_INSURERS,
    payload: {value}
  }
}

export const resetEditEmpresas = () => {
  return {
    type: ActionsTypes.RESET_EDIT_EMPRESAS,
    payload: {
      // label, value
    }
  }
}
export const resetCreateEmpresas = (label, value) => {
  return {
    type: ActionsTypes.RESET_CREATE_EMPRESAS,
    payload: {
      // label, value
    }
  }
}


export const setErrors = (errors) => {
  return {
    type: ActionsTypes.EMPRESAS_SET_ERRORS,
    payload: errors
  }
}

export const createTarjeta = (data) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_CREATE_TARJETA,
    axios.post("/v1/smart-cards",data)
  )
}

export const editTarjeta = (id, data) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_EDIT_TARJETA,
    axios.put(`/v1/smart-cards/${id}`,data)
  )
}

export const deleteTarjeta = (id) => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_DELETE_TARJETA,
    axios.delete(`/v1/smart-cards/${id}`)
  )
}

export const getTarjeta = () => dispatch => {
  exec(
    dispatch,
    ActionsTypes.API_GET_TARJETA,
    axios.get("/v1/smart-cards")
  )
}


import * as ActionsTypes from "./types";
import axios from "axios";

const ini = {
  isAuthorized: false,
  token: "",
  token_type: "",
  rol: "",
  refreshToken: "",
  expiresIn: 0,
  id: "",
  success: null,
  messages: "",
  fetching: null,
  fetched: null,
  resetPassword: {
    fetching: null,
    fetched: null,
    error: null,
    result: null,
  },
  restorePassword: {
    fetching: null,
    fetched: null,
    error: null,
    result: null,
  },
  validToken: {
    fetching: null,
    fetched: null,
    error: null,
    result: null,
  },
  iframeAuth: null,
  validate_status: null,
  refresh_token_status: null,
  profile:{},
  affiliateLink: false
};

export default (state = ini, action) => {
  switch (action.type) {
    case ActionsTypes.API_POST_LOGIN + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching: true,
      };
    case ActionsTypes.API_POST_LOGIN + ActionsTypes.FETCHED:
      const res = action.payload.data;
      if (res.success) {
        axios.defaults.headers['Authorization'] = 'Bearer ' + res.token.access_token;
        localStorage.setItem("token", res.token.access_token);
        return {
          ...state,
          isAuthorized: true,
          success: res.success,
          token: res.token.access_token,
          token_type: res.token.token_type,
          fetched: true,
          fetching: false
        };
      } else {
        return {
          ...state,
          success: res.success,
          token: "",
          token_type: "",
          message: res.message,
          fetched: true,
          fetching: false
        };
      }
    case ActionsTypes.VALIDATE_TOKEN + ActionsTypes.FETCHING:
      return {
        ...state,
        validate_status: "fetching"
      };
    case ActionsTypes.VALIDATE_TOKEN + ActionsTypes.FETCHED:
      const {message} = action.payload.data;
      if(message == "Token is valid"){
        return {
          ...state,
          validate_status: "fetched"
        };
      }else{
        return {
          ...state,
          validate_status: "error"
        };
      }
    case ActionsTypes.REFRESH_TOKEN + ActionsTypes.FETCHING:
      return {
        ...state,
        refresh_token_status: "fetching"
      };
    case ActionsTypes.REFRESH_TOKEN + ActionsTypes.FETCHED:
        res = action.payload.data;
        axios.defaults.headers['Authorization'] = 'Bearer ' + res.token;
        localStorage.setItem("token", res.token);
        return {
          ...state,
          iframeAuth: {
            ...state.iframeAuth,
            token:res.token
          },
          refresh_token_status: "fetched"
        }
    case ActionsTypes.RESET_STATUS_AUTH:
      return {
        ...state,
        validate_status: null,
        refresh_token_status: null
      }
    case ActionsTypes.SET_AFFILIATE_LINK:
      axios.defaults.params = {}
      axios.defaults.params['affiliateLink'] = action.payload
      return {
        ...state,
        affiliateLink:action.payload
      }
    case ActionsTypes.SET_AUTH_DATA:
      const data = action.payload;
      if(data.token){
        axios.defaults.headers['Authorization'] = 'Bearer ' + data.token;
        localStorage.setItem("token",  data.token);
        if(!!data.affiliateLink){
          axios.defaults.params = {}
          axios.defaults.params['affiliateLink'] = data.affiliateLink
        }
        return {
          ...state,
          iframeAuth: data,
          affiliateLink: !!data.affiliateLink?data.affiliateLink:false
          //iframeAuthStatus: "success",
        }
      }else{
        return {
          ...state,
          iframeAuth: null
        }
      }
    case ActionsTypes.SET_AUTH_DATA2:
      let data2 = action.payload;
      if(data2.token){
        let  params = axios.defaults.params | {};
        if(params === 0){
          params = {}
        }
        axios.defaults.headers['Authorization'] = 'Bearer ' + data2.token;
        axios.defaults.params = params;
        params.clientToken =  data2.token;
        localStorage.setItem("token2", data2.token);
        return {
          ...state,
          iframeAuth: data2
          //iframeAuthStatus: "success",
        }
      }else{
        return {
          ...state,
          iframeAuth: null
        }
      }
    case ActionsTypes.API_POST_RESET_PASSWORD + ActionsTypes.FETCHING:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          fetching: true,
          fetched: false,
          error: false,
          result: null,
        }
      };
    case ActionsTypes.API_POST_RESET_PASSWORD + ActionsTypes.FETCHED:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          fetching: false,
          fetched: true,
          error: false,
          result: action.payload.data,
        }
      };
    case ActionsTypes.GET_PROFILE_B2C + ActionsTypes.FETCHED:
      return {
        ...state,
        profile:action.payload.data
      }
    case ActionsTypes.RESET_PROFILE:
      return {
        ...state,
        profile:null
      }
    case ActionsTypes.API_POST_RESET_PASSWORD + ActionsTypes.ERROR:
      return {
        ...state,
        resetPassword: {
          ...state.resetPassword,
          fetching: false,
          fetched: true,
          error: true,
          result: {
            success: false,
            message: "Error de conexion, por favor intentelo mas tarde."
          },
        }
      };
    case ActionsTypes.API_POST_RESTORE_PASSWORD + ActionsTypes.FETCHING:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          fetching: true,
          fetched: false,
          error: false,
          result: null,
        }
      };
    case ActionsTypes.API_POST_RESTORE_PASSWORD + ActionsTypes.FETCHED:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          fetching: false,
          fetched: true,
          error: false,
          result: action.payload.data,
        }
      };
    case ActionsTypes.API_POST_RESTORE_PASSWORD + ActionsTypes.ERROR:
      return {
        ...state,
        restorePassword: {
          ...state.restorePassword,
          fetching: false,
          fetched: true,
          error: true,
          result: {
            success: false,
            message: "Error de conexion, por favor intentelo mas tarde."
          },
        }
      };
    case ActionsTypes.API_POST_VALID_TOKEN + ActionsTypes.FETCHING:
      return {
        ...state,
        validToken: {
          ...state.validToken,
          fetching: true,
          fetched: false,
        }
      };
    case ActionsTypes.API_POST_VALID_TOKEN + ActionsTypes.FETCHED:
      return {
        ...state,
        validToken: {
          ...state.validToken,
          fetching: false,
          fetched: true,
          error: false,
          result: action.payload.data,
        }
      };
    case ActionsTypes.API_POST_VALID_TOKEN + ActionsTypes.ERROR:
      return {
        ...state,
        validToken: {
          ...state.validToken,
          fetching: false,
          fetched: true,
          error: true,
          result: {
            success: false,
            message: "Error de conexion, por favor intentelo mas tarde."
          },
        }
      };
    case ActionsTypes.AUTH_LOGOUT:
      return {
        token: "",
        token_type: "",
        rol: "",
        refreshToken: "",
        expiresIn: 0,
        id: "",
        success: null,
        messages: "",
        fetching: null,
        fetched: null
      };
    case ActionsTypes.UNAUTHORIZED:
      return {
        isAuthorized: true
      }
    case ActionsTypes.RESET:
      return {
        ...state,
        token: "",
        token_type: "",
        rol: "",
        refreshToken: "",
        expiresIn: 0,
        id: "",
        success: null,
        messages: "",
        fetching: null,
        fetched: null,
        resetPassword: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
        restorePassword: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
        validToken: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
      };
    case ActionsTypes.AUTH_INITIAL_RESET_PASSWORD:
      return {
        ...state,
        resetPassword: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
        restorePassword: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
        validToken: {
          fetching: null,
          fetched: null,
          error: null,
          result: null,
        },
      };
    default:
      return state;
  }
};

export const FETCHING = '_FETCHING'
export const ERROR = '_ERROR'
export const FETCHED = '_FETCHED'

export const API_POST_LOGIN = 'API_POST_LOGIN'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const UNAUTHORIZED = "UNAUTHORIZED"
export const API_POST_RESET_PASSWORD = "API_POST_RESET_PASSWORD"
export const API_POST_RESTORE_PASSWORD = "API_POST_RESTORE_PASSWORD"
export const AUTH_INITIAL_RESET_PASSWORD = "AUTH_INITIAL_RESET_PASSWORD"
export const AUTH_INITIAL_RESTORE_PASSWORD = "AUTH_INITIAL_RESTORE_PASSWORD"
export const RESET = '_RESET'
export const API_POST_VALID_TOKEN = "API_POST_VALID_TOKEN"
export const SET_AUTH_DATA = "SET_AUTH_DATA"
export const SET_AUTH_DATA2 = "SET_AUTH_DATA2"
export const VALIDATE_TOKEN = "VALIDATE_TOKEN"
export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const RESET_STATUS_AUTH = "RESET_STATUS_AUTH"
export const GET_PROFILE_B2C = "GET_PROFILE_B2C"
export const RESET_PROFILE = "RESET_PROFILE"
export const SET_AFFILIATE_LINK = "SET_AFFILIATE_LINK"

import { config } from "aws-sdk";
import { createEmpresa, editEmpresa } from "./action";
import * as ActionsTypes from "./types";

const ini = {
  empresas: [],
  empresas_filter: [],
  tarjetas: [],
  createEmpresa: {
    company: {
      name: '',
      businessName: '',
      state: null,
      type: null,
      web: '',
      vat: '',
      phone: '',
      email: '',
      address: '',
      zip: '',
      countries: [],
      cities: [],
      paymentFormat: '',
      plazo: '',
      insurers: [],
      country: null,
      city: null,
      domine: null,
    },
    admin: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      position: '',
      area: '',
      code: '77777777',
      password: '',
      passwordConfirmation: '',
    },
    configs: {
      color_primary: '#3283ff',
      color_secondary: '#47C6D3',
      color_accent: '#47C6D3',
      header_title: '',
      header_subtitle: '',
      company_description: '',
      about_us_header_title: '',
      about_us_header_subtitle: '',
      about_us_company_description: '',
      extra_data: {
        broker_id: {
          'img': {
            value: ''
          },
          'tokio-marine': {
            value: ''
          },
          'europ-assistance': {
            value: ''
          },
        }
      },
    },
    files: {
      logo: null,
      header_background: null,
      about_us_header_background: null,
    },
    insurers: [],
    info: {
      statuses: [
        {
          value: null,
          text: "Estado de empresa"   // primer estado para la empresa (representacion del texto)
        },
        {
          value: 1,
          text: "Activa",
        },
        {
          value: 0,
          text: "Inactiva",
        }
      ],
      types: [],
      types_fetching: null,
      types_fetched: null,
      countries: [],
      fiscalCountries: [],
      countries_fetching: null,
      countries_fetched: null,
      cities: [],
      cities_fetching: null,
      cities_fetched: null,
      fiscal_cities: [],
      fiscal_cities_fetching: null,
      fiscal_cities_fetched: null,
      selectedCountry: null,
      insurers: [],
      insurers_fetching: null,
      insurers_fetched: null,
      store_fetching: false,
      store_fetched: false,
      store_result: null,
      store_error: false,
      sectors: [],
      sectors_fetching: false,
      sectors_fetched: false
    },
    errors: {
      company: {
        name: false,
        businessName: false,
        state: false,
        phone: false,
        address: false,
        countries: false,
        cities: false,
        country: false,
        web: false,
        vat: false,
        typpe: false,
        email: false,
        zip: false,
        fiscalCity: false,
        city: false,
        maxcaracter_header_subtitle: false,
        mincaracter_header_subtitle: false,
        maxcaracter_company_description: false,
        mincaracter_company_description: false,
        maxcaracter_about_us_company_description: false,
        mincaracter_about_us_company_description: false,
        maxcaracter_about_us_header_subtitle: false,
        mincaracter_about_us_header_subtitle: false
      },
      admin: {
        name: false,
        email: false,
        area: false,
        code: false,
        web: false,
        api: false,
        lastName: false,
        phone: false,
        position: false,
        password: false,
        passwordConfirmation: false,
        passwordMatch: true,
      },
    },
    sectors: [],
  },
  editEmpresa: {
    company: {
      name: '',
      businessName: '',
      state: null,
      type: null,
      web: '',
      vat: '',
      phone: '',
      email: '',
      address: '',
      zip: '',
      countries: [],
      cities: [],
      paymentFormat: '',
      plazo: '',
      insurers: [],
      country: null,
      city: null,
      domine: null
    },
    admin: {
      name: '',
      lastName: '',
      email: '',
      phone: '',
      position: '',
      area: '',
      code: '',
      password: '',
      passwordConfirmation: '',
    },
    configs: {
      color_primary: '#3283ff',
      color_secondary: '#47C6D3',
      color_accent: '#47C6D3',
      header_title: '',
      header_subtitle: '',
      company_description: '',
      about_us_header_title: '',
      about_us_header_subtitle: '',
      about_us_company_description: '',
      extra_data: {
        broker_id: {
          'img': {
            value: ''
          },
          'tokio-marine': {
            value: ''
          },
          'europ-assistance': {
            value: ''
          },
        }
      },
    },
    files: {
      logo: null,
      header_background: null,
      about_us_header_background: null,
    },
    insurers: [],
    info: {
      statuses: [
        {
          value: null,
          text: "Estado de empresa"   // primer estado para la empresa (representacion del texto)
        },
        {
          value: 1,
          text: "Activa",
        },
        {
          value: 0,
          text: "Inactiva",
        }
      ],
      types: [],
      types_fetching: null,
      types_fetched: null,
      countries: [],
      countries_fetching: null,
      countries_fetched: null,
      cities: [],
      cities_fetching: null,
      cities_fetched: null,
      selectedCountry: null,
      insurers: [],
      insurers_fetching: null,
      insurers_fetched: null,
      store_fetching: false,
      store_fetched: false,
      store_result: null,
      store_error: false,
      updateEmpresaSelect: false,
      updateEmpresaSelect_fetching: false,
      updateEmpresaSelect_fetched: false,
    },
    blocked: true
  },
  empresas_fetching: false,
  empresas_fetched: false,
  empresa_select: null,
  empresa_select_id: null,
  empresa_select_id_fetching: null,
  empresa_select_id_fetched: null,
  empresa_select_id_error: null,
  empresas_active_fetching: false,
  empresas_active_fetched: false,
  empresas_active_result: false,
  fetching_tarjeta: false,
  fetched_tarjeta: false,
  error_tarjeta: false,
  fetching_delete_tarjeta: false,
  fetched_delete_tarjeta: false,
  error_delete_tarjeta: false,
  filters: {
    countries: [],
    cities: [],
  },
};

const temp = JSON.parse(JSON.stringify(ini));

export default (state = ini, action) => {
  switch (action.type) {
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_INITIAL:
      return {
        ...state,
        createEmpresa: temp.createEmpresa,
        empresas: temp.empresas,
        empresa_select: temp.empresa_select,
      }
    case ActionsTypes.RESET_CREATE_EMPRESAS:
      return {
        ...state,
        createEmpresa: temp.createEmpresa,
      }
    case ActionsTypes.RESET_EDIT_EMPRESAS:
      return {
        ...state,
        editEmpresa: temp.editEmpresa,
      }
    //Actualizando estado de client id de IMG y Tokio Marine
    //extra_data:{'broker_id':{'img':{'value':''},'tokio-marine':{'value':''}}},
    case ActionsTypes.UPDATE_CLIENT_ID_COMPANIES:
      // var broker_id={};
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          configs: {
            ...state.createEmpresa.configs,
            extra_data: {
              ...state.createEmpresa.configs.extra_data,
              broker_id: {
                ...state.createEmpresa.configs.extra_data.broker_id,
                [action.payload.name]: { 'value': action.payload.value },//value es {'value':'client_id'}
              }
            }
          }
        }
      }
    case ActionsTypes.UPDATE_CLIENT_ID_COMPANIES_EDIT:
      console.log(action.payload);
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          configs: {
            ...state.editEmpresa.configs,
            extra_data: {
              ...state.editEmpresa.configs.extra_data,
              broker_id: {
                ...state.editEmpresa.configs.extra_data.broker_id,
                [action.payload.name]: { 'value': action.payload.value },//value es {'value':'client_id'}
              }
            }
          }
        }
      }
    case ActionsTypes.UPDATE_CLIENT_ID_COMPANIES_CLEAR:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          configs: {
            ...state.editEmpresa.configs,
            extra_data: {
              ...state.editEmpresa.configs.extra_data,
              broker_id: []
            }
          }
        },
        createEmpresa: {
          ...state.createEmpresa,
          configs: {
            ...state.createEmpresa.configs,
            extra_data: {
              ...state.createEmpresa.configs.extra_data,
              broker_id: []
            }
          }
        }

      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS:
      return {
        ...state,
        createEmpresa: {

          ...state.createEmpresa,
          company: {
            ...state.createEmpresa.company,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_SECTORS:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          sectors: action.payload
        }
      }

    case ActionsTypes.UPDATE_EDIT_EMPRESAS_SECTORS:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          sectors: action.payload
        }
      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_ADMIN:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          admin: {
            ...state.createEmpresa.admin,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_EDIT_EMPRESAS_ADMIN:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          admin: {
            ...state.editEmpresa.admin,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_CONFIGS:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          configs: {
            ...state.createEmpresa.configs,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_EDIT_EMPRESAS_CONFIGS:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          configs: {
            ...state.editEmpresa.configs,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.API_GET_EMPRESAS_TYPES + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            types_fetching: true,
            types_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_EMPRESAS_TYPES + ActionsTypes.FETCHED:
      let types = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.name
        };
      })
      types.unshift({
        id: null,
        text: "Tipo de empresa"
      });
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            types: types,
            types_fetching: false,
            types_fetched: true
          }
        }
      }
    case ActionsTypes.API_GET_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        empresas_fetching: true,
        empresas_fetched: false
      }
    case ActionsTypes.API_GET_EMPRESAS + ActionsTypes.FETCHED:
      return {
        ...state,
        empresas_fetching: false,
        empresas_fetched: true,
        empresas: action.payload.data,
        empresas_filter: action.payload.data,
      }

    case ActionsTypes.API_GET_EMPRESA + ActionsTypes.FETCHING:
      return {
        ...state,
        empresa_select_id_fetching: true,
        empresa_select_id_fetched: false,
        empresa_select_id_error: false,
      }
    case ActionsTypes.API_GET_EMPRESA + ActionsTypes.FETCHED:
      let sectors = [];
      let { data } = action.payload;
      if (data && data.sectors) {
        data.sectors.forEach(element => {
          sectors.push(element.sector);
        });
      }

      return {
        ...state,
        empresa_select_id_fetching: false,
        empresa_select_id_fetched: true,
        empresa_select_id_error: true,
        empresa_select: action.payload.data,
        createEmpresa: {
          ...state.createEmpresa,
          selectedCountry: {
            key: 0,
            country: { ...action.payload.data.country }
          },
          admin: {
            ...state.createEmpresa.admin,
            api: action.payload.data.platformType.api,
            web: action.payload.data.platformType.web_b2b,
          },
          sectors
        },
        editEmpresa: {
          ...state.editEmpresa,
          selectedCountry: {
            key: 0,
            country: { ...action.payload.data.country }
          },
          admin: {
            ...state.editEmpresa.admin,
            api: action.payload.data.platformType.api,
            web: action.payload.data.platformType.web_b2b,
          },
          sectors
        }
      }
    case ActionsTypes.API_GET_EMPRESA + ActionsTypes.ERROR:
      return {
        ...state,
        empresa_select_id_fetching: false,
        empresa_select_id_fetched: true,
        empresa_select_id_error: true,
        empresa_select: null,
      }
    case ActionsTypes.FILTER_EMPRESAS:
      return {
        ...state,
        empresas_filter: action.payload,
      }
    case ActionsTypes.API_GET_INSURERS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            insurers_fetching: true,
            insurers_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_INSURERS + ActionsTypes.FETCHED:

      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            insurers: action.payload.data.map((item) => {
              return {
                ...item,
                state: false,
                percentage: null,
                programs: item.programs.map((program) => {
                  return {
                    ...program,
                    state: true,
                    percentage: null,
                  }
                })
              }
            }),
            insurers_fetching: false,
            insurers_fetched: true
          }
        }
      }
    case ActionsTypes.API_GET_SECTORS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            sectors_fetching: true,
            sectors_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_SECTORS + ActionsTypes.FETCHED:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            sectors: action.payload.data,
            sectors_fetching: false,
            sectors_fetched: true
          }
        },
      }
    case ActionsTypes.API_GET_COUNTRIES_CREATE_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            countries_fetching: true,
            countries_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_COUNTRIES_CREATE_EMPRESAS + ActionsTypes.FETCHED:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            countries: action.payload.data.map((item) => {
              return {
                id: item.iso3,
                text: item.text
              };
            }),
            fiscalCountries: action.payload.data.map((item) => {
              return {
                id: item.id,
                text: item.text,
                iso3: item.iso3,
              };
            }),
            countries_fetching: false,
            countries_fetched: true
          }
        }
      }
    case ActionsTypes.API_EMPRESAS_FILTERS_COUNTRIES + ActionsTypes.FETCHED:
      let countries = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.text,
          iso3: item.iso3
        };
      })
      countries.unshift({
        id: null,
        text: "Pais"
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          countries: countries,
        }
      }
    case ActionsTypes.API_EMPRESAS_FILTERS_CITIES + ActionsTypes.FETCHED:
      let cities = action.payload.data.map((item) => {
        return {
          id: item.id,
          text: item.text
        };
      })
      cities.unshift({
        id: null,
        text: "Provincia - estado"
      });
      return {
        ...state,
        filters: {
          ...state.filters,
          cities: cities,
        }
      }
    case ActionsTypes.API_GET_CITIES_CREATE_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            cities_fetching: true,
            cities_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_CITIES_CREATE_EMPRESAS + ActionsTypes.FETCHED:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            cities: action.payload.data.map((item) => {
              return {
                id: item.id,
                text: item.text
              };
            }),
            cities_fetching: false,
            cities_fetched: true
          }
        }
      }
    case ActionsTypes.API_GET_TARJETA + ActionsTypes.FETCHED:
      return {
        ...state,
        tarjetas: action.payload.data
      }
    case ActionsTypes.API_EDIT_TARJETA + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching_tarjeta: true,
        fetched_tarjeta: false,
        error_tarjeta: false,
        msg_tarjeta: "",
      }
    case ActionsTypes.API_EDIT_TARJETA + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          fetching_tarjeta: false,
          fetched_tarjeta: true,
          error_tarjeta: false,
          msg_tarjeta: "",
        }
      } else {
        return {
          ...state,
          fetching_tarjeta: false,
          fetched_tarjeta: false,
          error_tarjeta: true,
          msg_tarjeta: action.payload.data.message,
        }
      }
    case ActionsTypes.API_DELETE_TARJETA + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching_delete_tarjeta: true,
        fetched_delete_tarjeta: false,
        error_delete_tarjeta: false,
      }
    case ActionsTypes.API_DELETE_TARJETA + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          fetching_delete_tarjeta: false,
          fetched_delete_tarjeta: true,
          error_delete_tarjeta: false,
        }
      } else {
        return {
          ...state,
          fetching_delete_tarjeta: false,
          fetched_delete_tarjeta: false,
          error_delete_tarjeta: true,
        }
      }
    case ActionsTypes.API_CREATE_TARJETA + ActionsTypes.FETCHING:
      return {
        ...state,
        fetching_tarjeta: true,
        fetched_tarjeta: false,
        error_tarjeta: false,
        msg_tarjeta: "",
      }
    case ActionsTypes.API_CREATE_TARJETA + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          fetching_tarjeta: false,
          fetched_tarjeta: true,
          error_tarjeta: false,
          msg_tarjeta: "",
        }
      } else {
        return {
          ...state,
          fetching_tarjeta: false,
          fetched_tarjeta: false,
          error_tarjeta: true,
          msg_tarjeta: action.payload.data.message,
        }
      }
    case ActionsTypes.API_CREATE_TARJETA + ActionsTypes.ERROR:
      return {
        ...state,
        fetching_tarjeta: false,
        fetched_tarjeta: false,
        error_tarjeta: false,
        msg_tarjeta: "",
      }
    case ActionsTypes.RESET_TARJETA_CONST:
      return {
        ...state,
        fetching_tarjeta: false,
        fetched_tarjeta: false,
        error_tarjeta: false,
        fetching_delete_tarjeta: false,
        fetched_delete_tarjeta: false,
        error_delete_tarjeta: false,
        msg_tarjeta: "",
      }
    case ActionsTypes.API_GET_FISCAL_CITIES_CREATE_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            fiscal_cities_fetching: true,
            fiscal_cities_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_FISCAL_CITIES_CREATE_EMPRESAS + ActionsTypes.FETCHED:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            fiscal_cities: action.payload.data.map((item) => {
              return {
                id: item.id,
                text: item.text
              };
            }),
            fiscal_cities_fetching: false,
            fiscal_cities_fetched: true
          }
        }
      }
    case ActionsTypes.API_POST_CREATE_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            store_fetching: true,
            store_fetched: false
          }
        }
      }
    case ActionsTypes.API_POST_CREATE_EMPRESAS + ActionsTypes.ERROR:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            store_fetching: false,
            store_fetched: false,
            store_error: true,
            store_result: action.payload.data,
          }
        }
      }
    case ActionsTypes.API_POST_CREATE_EMPRESAS + ActionsTypes.FETCHED:
      if (action.payload.data.success) {
        return {
          ...state,
          createEmpresa: {
            ...state.createEmpresa,
            id: action.payload.data.id,
            info: {
              ...state.createEmpresa.info,
              store_result: action.payload.data,
              store_fetching: false,
              store_error: false,
              store_fetched: true
            }
          }
        }
      } else {
        return {
          ...state,
          createEmpresa: {
            ...state.createEmpresa,
            id: action.payload.data.id,
            info: {
              ...state.createEmpresa.info,
              store_result: action.payload.data,
              store_fetching: false,
              store_error: true,
              store_fetched: false
            }
          }
        }
      }

    case ActionsTypes.UPDATE_CREATE_INFO:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          info: {
            ...state.createEmpresa.info,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.EMPRESAS_SELECT:
      return {
        ...state,
        empresa_select: action.payload
      }
    case ActionsTypes.EMPRESAS_SELECT_ID:
      return {
        ...state,
        empresa_select_id: action.payload
      }
    case ActionsTypes.API_PATCH_ACTIVE_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        empresas_active_fetching: true,
        empresas_active_fetched: false
      }
    case ActionsTypes.API_PATCH_ACTIVE_EMPRESAS + ActionsTypes.FETCHED:
      const result = action.payload.data;
      return {
        ...state,
        empresas_active_fetching: false,
        empresas_active_fetched: true,
        empresa_select: {
          ...state.empresa_select,
          active: result.success ? !state.empresa_select.active : state.empresa_select.active
        }
      }

    case ActionsTypes.UPDATE_EDIT_EMPRESAS_INITIAL:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          [action.payload.label]: action.payload.value
        }
      }
    case ActionsTypes.UPDATE_EDIT_EMPRESAS:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          company: {
            ...state.editEmpresa.company,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_FILES:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          files: {
            ...state.createEmpresa.files,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.UPDATE_EDIT_EMPRESAS_FILES:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          files: {
            ...state.editEmpresa.files,
            [action.payload.label]: action.payload.value
          }
        }
      }
    case ActionsTypes.API_GET_EDIT_INSURERS + ActionsTypes.FETCHING:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            insurers_fetching: true,
            insurers_fetched: false
          }
        }
      }
    case ActionsTypes.API_GET_EDIT_INSURERS + ActionsTypes.FETCHED:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          insurers: action.payload.data.map((insurer) => {
            return {
              id: insurer.id,
              state: true,
              percentage: insurer.percentage,
              programs: insurer.programs.map((program) => {
                return {
                  id: program.id,
                  state: true,
                  percentage: program.percentage,
                  sector: program.sector.id
                }
              })
            }
          }),
          info: {
            ...state.editEmpresa.info,
            insurers_fetching: false,
            insurers_fetched: true
          }
        }
      }
    case ActionsTypes.UPDATE_EDIT_EMPRESAS_INSURERS:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          insurers: action.payload.value
        }
      }
    case ActionsTypes.UPDATE_CREATE_EMPRESAS_INSURERS:
      return {
        ...state,
        createEmpresa: {
          ...state.createEmpresa,
          insurers: action.payload.value
        }
      }
    case ActionsTypes.API_PUT_EDIT_EMPRESAS + ActionsTypes.FETCHING:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            store_fetching: true,
            store_fetched: false
          }
        }
      }
    case ActionsTypes.API_PUT_EDIT_EMPRESAS + ActionsTypes.ERROR:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            store_fetching: false,
            store_fetched: false,
            store_error: true,
            store_result: action.payload.data,
          }
        }
      }
    case ActionsTypes.API_PUT_EDIT_EMPRESAS + ActionsTypes.FETCHED:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            store_result: action.payload.data,
            store_fetching: false,
            store_error: false,
            store_fetched: true
          }
        }
      }
    case ActionsTypes.API_UPDATE_EMPRESAS_SELECT + ActionsTypes.FETCHING:
      return {
        ...state,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            updateEmpresaSelect: false,
            updateEmpresaSelect_fetching: true,
            updateEmpresaSelect_fetched: false,
          }
        }
      }
    case ActionsTypes.API_UPDATE_EMPRESAS_SELECT + ActionsTypes.FETCHED:
      return {
        ...state,
        empresa_select: action.payload.data,
        editEmpresa: {
          ...state.editEmpresa,
          info: {
            ...state.editEmpresa.info,
            updateEmpresaSelect: true,
            updateEmpresaSelect_fetching: false,
            updateEmpresaSelect_fetched: true,
          }
        }
      }
    case ActionsTypes.EMPRESAS_SET_ERRORS:
      return {
        ...state,
        errors: action.payload,
      }
    default:
      return state;
  }
};

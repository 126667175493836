import React, { Component } from 'react';
import { HashRouter, Route, Switch, Router } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import history from './history';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const DefaultLayoutHome = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutHome'));
const DefaultLayoutCotiza = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutCotiza'));
const DefaultLayoutCotizaStem = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutCotizaStem'));
const DefaultLayoutIframe = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutIframe'));
const DefaultLayoutCotizaSalud = React.lazy(() => import('./containers/DefaultLayout/DefaultLayoutCotizaSalud'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading()}>
            <Switch>
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/dashboard" name="Dashboard" render={props => <DefaultLayout {...props}/>} />
              <Route path="/iframe" name="Iframe" render={props => <DefaultLayoutIframe {...props}/>} />
              <Route path="/salud" name="CotizaSalud" render={props => <DefaultLayoutCotizaSalud {...props}/>} />
              <Route path="/cotiza-email" name="CotizaEmail" render={props => <DefaultLayoutCotiza {...props}/>} />
              <Route path="/stem-email" name="CotizaEmailStem" render={props => <DefaultLayoutCotizaStem {...props}/>} />
              <Route path="/" name="test" render={props => <DefaultLayoutHome {...props}/>} />
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;

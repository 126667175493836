import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import "./css/style.css"
import './polyfill'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Provider } from 'react-redux'
import { store, persistor } from './redux/store';
import { PersistGate } from 'redux-persist/integration/react'
import {Config} from "./config/index";
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import "./interceptor";

axios.defaults.baseURL = Config.path;
axios.defaults.headers['Authorization'] = 'Bearer '+localStorage.getItem("token");


ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <App />
        </PersistGate>
    </Provider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
